import { FC, useEffect } from "react";
import {
  Box, Button, Divider, Typography
} from "@mui/material";
import { PasswordInput } from "../../password-input";
import { validation } from "../../../schemas/validation.schemas";
import { useDispatch, useSelector } from "react-redux";
import {
  addChangeNewPasswordAction,
  changePasswordAction,
  settingsChangePasswordSelector
} from "../../../store/slices/settingsSlice";
import { errorsChangePasswordSelector } from "../../../store/slices/errorSlice";
import { useFormikRef } from "../../../hooks";
import { setError } from "../../../helpers/setError";

export const FormChangePassword: FC = () => {
  const dispatch = useDispatch();
  const error = useSelector(errorsChangePasswordSelector);
  const changeState = useSelector(settingsChangePasswordSelector);

  const onSubmit = (values: { password: string, repPassword: string }) => {
    dispatch(addChangeNewPasswordAction(values.password));
    dispatch(changePasswordAction());
  };

  const formikRef = useFormikRef(
    { password: changeState.password, repPassword: '' },
    validation.changePassword(),
    onSubmit,
  );
  const formik = formikRef.current;

  useEffect(() => {
    if (error) {
      setError(formikRef.current, error, 'changePassword');
    }
  }, [error, formikRef]);

  return (

    <Box sx={{ maxWidth: '300px', mt:10, marginLeft: 'auto', marginRight: 'auto' }}>

      <Typography fontSize="xx-large" component="h1" variant="h5">
                Change password
      </Typography>

      <Box className="change-password_form" component="form" sx={{ mt: 1 }} onSubmit={formik.handleSubmit}>
        <PasswordInput
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors?.password)}
          helperText={formik.errors?.password as string}
        />

        <PasswordInput
          value={formik.values.repPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors?.repPassword)}
          helperText={formik.errors?.repPassword as string}
          label="Repeat new password"
          name="repPassword"
        />

        <Divider sx={{ border: '1px solid #000', opacity: '0.25', mb: '1rem' }} variant="middle" />
        <Button fullWidth color="success" variant="contained" type="submit" >Change</Button>
      </Box>
    </Box>
  );
};
