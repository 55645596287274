import {
  call, put, select
} from "@redux-saga/core/effects";

import { hubUpdateMessages } from "../../../hub";
import { IHubResponse } from "../../../models";
import { changeLastMessageInChatItem } from "../../slices/chatSlices/chatsListSlice";
import {
  messageSliceClearState,
  messageSliceEditTextSelector,
  messageSliceIdSelector
} from "../../slices/chatSlices/messageSlice";
import { activeChatIdSelector, updateMessageActiveChatAction } from "../../slices/chatSlices/activeChatSlice";
export function* updateMessageSaga(): Generator {
  try {
    const chatId = yield select(activeChatIdSelector);
    const messageId = yield select(messageSliceIdSelector);
    const text = yield select(messageSliceEditTextSelector);

    const res = yield call(() => hubUpdateMessages(
      {
        chatId: (chatId as string),
        messageId: (messageId as string),
        text: (text as string)
      }
    ));

    if (!(res as IHubResponse).success) {
      throw res;
    }
    else {
      yield put(updateMessageActiveChatAction({ messageId: messageId as string, text: text as string }));
      yield put(changeLastMessageInChatItem({ id: (chatId as string), message: text, unread: 0, role: 'upd' }));
    }
  }

  catch (e) {
    console.log(e);
  }
  finally {
    yield put(messageSliceClearState());
  }
}
