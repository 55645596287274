import { IAction, IHubResponse } from "../../../models";
import {
  select, put, call
} from "@redux-saga/core/effects";
import { hubDeleteMessage } from "../../../hub";
import { getChatsItemAction } from "../../slices/chatSlices/chatsListSlice";
import { activeChatIdSelector, deleteMessageActiveChatAction } from "../../slices/chatSlices/activeChatSlice";

export function* deleteMessageSaga(action: IAction<string>): Generator {
  try {
    const activeChatId = yield select(activeChatIdSelector);
    const res = yield call(() => hubDeleteMessage({ messageId: action.payload, chatId: (activeChatId as string) }));
    if ((res as IHubResponse).success) {
      yield put(deleteMessageActiveChatAction({ messageId: action.payload }));
      yield put(getChatsItemAction(activeChatId));
    }

    else throw res;
  }
  catch (e) {
    console.log(e);
  }
}
