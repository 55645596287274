import { IChatItem } from "../models";

export const sortChatList = (arr: IChatItem[] | null, id: string) => {
  if (!arr || arr.findIndex((el: IChatItem) => el.id === id) === -1) {
    return arr;
  }
  else {
    const index = arr.findIndex((el: IChatItem) => el.id === id);
    const item = arr.splice(index, 1);

    return [item[0], ...arr];
  }
};
