import $api from "../http";
import { AxiosError, AxiosResponse } from "axios";
import { IError, IUserState } from "../models";
import { checkResponse } from "./checkResponse";
import { transformError } from "../helpers";

const chatPath = '/Chat';
const chatsListPath = `${chatPath}/list`;
const chatItemPath = `${chatsListPath}/item?chatId=`;
const chatMembersPath = `${chatPath}/members?chatId=`;
const chatMessagesPath = `${chatPath}/messages`;
const contactsPath: string = `/Admin/users`;

export const fetchGetChats = () => {
  return $api.get(chatsListPath).then(res => checkResponse(res)).catch(e => transformError(e));
};

export const fetchGetChat = (id: string) => {
  return $api.get(`${chatPath}?chatId=${id}`).then(res => checkResponse(res)).catch(e => transformError(e));
};

export const fetchGetContacts = (): Promise<AxiosResponse<IUserState> | IError> => {
  return $api.get(contactsPath).then(res => checkResponse(res)).catch((e: AxiosError) => transformError(e));
};

export const fetchGetChatItem = (id: string): Promise<AxiosResponse<IUserState> | IError> => {
  return $api.get(chatItemPath+id).then(res => checkResponse(res)).catch((e: AxiosError) => transformError(e));
};

export const fetchGetMessages = (data: any): Promise<AxiosResponse<IUserState> | IError> => {
  return $api.post(chatMessagesPath, data).then(res => checkResponse(res)).catch((e: AxiosError) => transformError(e));
};
export const fetchGetMembers = (id: string): Promise<AxiosResponse<IUserState> | IError> => {
  return $api.get(chatMembersPath+id).then(res => checkResponse(res)).catch((e: AxiosError) => transformError(e));
};
