import {
  tokensExpireSelector,
  tokensAccessSelector, tokensRefreshSelector, writeTokensAction,
} from "../../store/slices/tokensSlice";
import { checkExpire } from "../../helpers";
import { InternalAxiosRequestConfig } from "axios";
import { IStore } from "../../models";
import { fetchRefreshToken } from "../../services/RefreshService";
import { errorsClear } from "../../store/slices/errorSlice";

let isRefreshing = false;
export const handleRequest = async (config: InternalAxiosRequestConfig, store: IStore) => {
  if (!(config.url as string).includes('refresh') && !(config.url as string).includes('signout')) {
    const expire = tokensExpireSelector(store.getState());
    if (expire && checkExpire(+expire) && !isRefreshing) {
      isRefreshing = true;
      const refToken = tokensRefreshSelector(store.getState());
      const refResponse = await fetchRefreshToken(refToken);
      store.dispatch(writeTokensAction(refResponse));
      store.dispatch(errorsClear());
      config.headers['ROOT-ACCESS'] = (refResponse as any).accessToken;
      isRefreshing = false;

      return config;
    }
  }
  const token = tokensAccessSelector(store.getState());
  config.headers['ROOT-ACCESS'] = `${token}`;

  return config;
};
