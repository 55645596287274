import React, {
  FC, useEffect, useRef, useState
} from "react";
import { Box, Typography } from "@mui/material";
import { CustomMenu } from "../../menu";
import './style.scss';
import { IMessageState } from "../../../models/states/IChatsState";
import { getMessageTime } from "../../../helpers";
import CheckIcon from '@mui/icons-material/Check';
import { text } from "stream/consumers";

type TMessageProps = {
    message: IMessageState
    senderName: string,
    position: string,
    isName: boolean,
    menuItems: any,
    isSeen: boolean,
    sendMsg: (data: { id: string, text: string }) => void,
};

export const MessageCard: FC<TMessageProps> = (props) => {
  const {
    message,
    senderName,
    position,
    isName,
    menuItems,
    isSeen,
    sendMsg,
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const msgRef = useRef();
  const open = Boolean(anchorEl);
  const [linesText, setLinesText] = useState<string[]>([]);
  const [isMouseLeft, setIsMouseLeft] = useState(false);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (!isName) {
      if (!isMouseLeft) {
        sendMsg({ id: message.id, text: message.text });
        setAnchorEl(event.currentTarget);
      }
      else {
        sendMsg({ id: '', text: '' });
        setAnchorEl(null);
      }
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (message.text && message.text.includes('\n')) {
      setLinesText(message.text.split('\n'));
    }

    const cur = msgRef.current;
    if (cur && !isName) {
      (cur as HTMLElement).addEventListener('mouseout', () => setIsMouseLeft(true));
      (cur as HTMLElement).addEventListener("mouseover", () => setIsMouseLeft(false));
    }

    return () => {
      if (cur) {
        (cur as HTMLElement).removeEventListener('mouseout', () => setIsMouseLeft(true));
        (cur as HTMLElement).removeEventListener("mouseover", () => setIsMouseLeft(false));
      }
    };
  }, [isName, message]);

  const messageText = message.text && linesText.length > 1 ?
    linesText.map((el, i) => <Typography key={i} className="message-card__text">{el}</Typography>) :
    <Typography className="message-card__text">{message.text}</Typography>;

  return (
    <Box ref={msgRef} id="message" className={`message-card message_${position}`} onContextMenu={handleOpen} >
      <Box className={'message-cont'}>
        {
          isName &&
                        <Typography className={'message-card__sender'}>
                          {senderName}
                        </Typography>
        }
        {messageText}
      </Box>

      <Box className="message-info">
        {message.isModified && <Typography className={'message-card__time'}>edited</Typography>}
        <Typography className={'message-card__time'}>{getMessageTime(message.sentDate, 'time')}</Typography>
        {isSeen ? <CheckIcon sx={{ fontSize: '14px' }} /> : null}
      </Box>

      {
        !isName && <CustomMenu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'message',
          }}
          el={anchorEl}
          open={open}
          onClose={handleClose}
          items={menuItems}
        />
      }
    </Box>
  );
};
