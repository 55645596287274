import { FC } from "react";
import {
  Box, Button, Typography
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { uiMessageSelector, uiChangeIsShowMessageAction } from "../../store/slices/uiSlice";
import './styles.scss';

const OperationButtons: FC<{ role: 'inf' | 'del' | 'acc', callback?: () => void, close: () => void }> = ({ role, callback, close }) => {
  const handleOperation = () => {
    if (callback) {
      callback();
    }
    close();
  };
  switch (role) {
  case 'del':
    return <Box className="banner-buttons">
      <Button onClick={close}>Close</Button>
      <Button onClick={handleOperation}>Delete</Button>
    </Box>;
  case 'acc' :
    return <Box className="banner-buttons">
      <Button onClick={close}>No</Button>
      <Button onClick={handleOperation} > Yes </Button>
    </Box>;
  case 'inf' :
    return <Box className="banner-buttons">
      <Button onClick={handleOperation}>Ok</Button>
    </Box>;
  default: return null;
  }
};

export const MessageBanner: FC<{ delCall?: () => void }> = ({ delCall }) => {
  const message = useSelector(uiMessageSelector);
  const dispatch = useDispatch();

  return (
    <Box className="banner">

      <Typography variant="h5" fontWeight="bold">
        {message.title}
      </Typography>

      <Typography className="banner-message">
        {message.desc}
      </Typography>
      <OperationButtons role={message.role} callback={message.callback} close={() => dispatch(uiChangeIsShowMessageAction(false))} />
    </Box>
  );
};

