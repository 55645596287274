
interface IDictionary<T> {
    [key: string]: T
}

type creatTeDictionary = <T>(arg: T[]) => IDictionary<T>

export const createDictionaryFromArray: creatTeDictionary = (arr) => {
  const dictionary: IDictionary<any> = {};
  arr.forEach(el => {
    // @ts-ignore
    dictionary[el.id] = {
      ...el
    };
  });

  return dictionary;
};
