import React, {
  FC, useEffect, useState
} from "react";
import {
  Box, IconButton, Typography
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SendIcon from '@mui/icons-material/Send';
import './style.scss';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { useFormikRef } from "../../../hooks";
import { validation } from "../../../schemas/validation.schemas";
import { IActiveChatState } from "../../../models/states/IChatsState";
import { MessagesList } from "../messages-list";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { CustomModal } from "../../modal";
import { ChatInfo } from "../chat-info";
import { activeChatIsLoadingSelector } from "../../../store/slices/chatSlices/activeChatSlice";
import {
  messageSliceChangeEditTextAction,
  messageSliceChangeIsEditAction, messageSliceEditTextSelector, messageSliceIsEditSelector, sendMessageAction, updateMessagesAction
} from "../../../store/slices/chatSlices/messageSlice";
type THeaderProps = {
    chat: IActiveChatState,
    handleClick: () => void
}

const ChatContentHeader: FC<THeaderProps> = ({ chat, handleClick }) => {
  return (
    <Box className="chat-content__header">
      <Box className="chat-content__inner" onClick={handleClick}>
        <Typography className="header__label">{chat.name}</Typography>
        {chat.chatType.toLowerCase() !== 'private' ?
          <Typography className="header__members">
                        members: {Object.keys(chat.members).length}
          </Typography>
          : null
        }
      </Box>
    </Box>
  );
};

type TProps = {
    chat: IActiveChatState,
}

const ChatContentInput: FC<TProps> = ({ chat }) => {
  const dispatch = useDispatch();
  const isEdit = useSelector(messageSliceIsEditSelector);
  const editText = useSelector(messageSliceEditTextSelector);
  const [value, setValue] = useState(isEdit ? editText : '');

  const cancelEdit = () => {
    dispatch(messageSliceChangeEditTextAction(''));
    dispatch(messageSliceChangeIsEditAction(false));

    setValue('');
  };

  const onSubmit = () => {
    if (!isEdit) {
      const date = new Date();
      const data = {
        chatId: chat.id,
        text: value,
        timestamp: date.toISOString()
      };

      dispatch(sendMessageAction(data));
    }
    else {
      dispatch(messageSliceChangeEditTextAction(value));
      dispatch(updateMessagesAction());
    }
    setValue('');
  };

  const keyPressHandler = (e: any) => {
    if (e.shiftKey && e.keyCode === 13) {
      e.preventDefault();
      setValue(value+'\r\n');
    }
    else if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      onSubmit();
    }
  };

  useEffect(() => {
    setValue(editText);
  }, [editText]);

  const formikRef = useFormikRef(
    { message: isEdit ? editText : '' },
    validation.message(),
    onSubmit,
  );
  const formik = formikRef.current;

  return (<>
    {isEdit &&
                <Box className="edit">
                  <Box className="edit_header">
                    <Typography className="input-edit__title">
                      <EditIcon /> Edit message
                    </Typography>
                    <IconButton onClick={cancelEdit} className="button">
                      <CloseIcon></CloseIcon>
                    </IconButton>
                  </Box>

                  <Typography className="edit-text">
                    {value}
                  </Typography>

                </Box>
    }
    <Box className="input-container" component="form" onSubmit={formik.handleSubmit}>

      <TextareaAutosize
        onKeyDown={keyPressHandler}
        name="message"
        className="input"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        maxRows={4}
        placeholder="Message..."
        autoFocus={true}
      />

      <IconButton type="submit" className="button">
        {!isEdit ? <SendIcon className="button__icon"/> : <CheckIcon className="button__icon" />}
      </IconButton>

    </Box>
  </>

  );
};

type TChatProps = {
    chat?: IActiveChatState | null,
}

export const ChatContent: FC<TChatProps> = ({ chat }) => {
  const chatIsLoading = useSelector(activeChatIsLoadingSelector);
  const [isShowModal, setIsShowModal] = useState(false);

  return (
    <>
      <Box className={'chat-content'}>

        {
          chatIsLoading
            ?
            <Typography>Loading...</Typography>
            :
            <>
              {chat && chat.id ?
                <>
                  <ChatContentHeader handleClick={() => setIsShowModal(!isShowModal)} chat={(chat as IActiveChatState)} />

                  <MessagesList chat={chat} />
                  <ChatContentInput chat={chat} />
                  <CustomModal
                    width={'360px'}
                    open={isShowModal}
                    handleShow={() => setIsShowModal(!isShowModal)}
                    children={<ChatInfo chat={chat} />}
                    isOwnWrap={true}
                  />
                </>
                :
                <Typography>
                                Select a chat
                </Typography>

              }
            </>
        }
      </Box>

    </>

  );
};
