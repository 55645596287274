import React, { useEffect } from 'react';
import './App.scss';
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import {
  CustomModal, ErrorBanner, MessageBanner
} from "./components";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { uiChangeIsShowMessageAction, uiMessageSelector } from "./store/slices/uiSlice";
import { tokensAccessSelector } from "./store/slices/tokensSlice";
import { hubStartConnection } from "./hub";
import { useCheckExpireByView } from "./hooks";

function App() {
  const dispatch = useDispatch();
  const message = useSelector(uiMessageSelector);
  const token = useSelector(tokensAccessSelector);

  useEffect(() => {
    if (token) {
      hubStartConnection();
    }
  }, [token]);

  useCheckExpireByView();

  return (
    <Box component="main" className="App">
      <CustomModal
        handleShow={() => dispatch(uiChangeIsShowMessageAction(!message.isShow))}
        open={message.isShow}
        width={'360px'}
        children={<MessageBanner />}
      />

      <ErrorBanner></ErrorBanner>
      <RouterProvider router={router}/>
    </Box>

  );
}

export default App;
