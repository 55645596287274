import {
  IAction, IError, IMemberState
} from "../../../models";
import { call, put } from "@redux-saga/core/effects";
import { fetchGetMembers } from "../../../services/ChatService";
import { IMemberDictionary } from "../../../models/states/IChatsState";
import { addMembersActiveChatAction } from "../../slices/chatSlices/activeChatSlice";
import { addMembersStoredChatAction } from "../../slices/chatSlices/storedChatsSlice";
import { createDictionaryFromArray } from "../../../helpers";

export function* getMembersSaga(action: IAction<string>): Generator {
  try {
    const res = yield call(() => fetchGetMembers(action.payload));
    if ((res as object).hasOwnProperty('errorCode') && (res as IError).errorCode !== 10) {
      throw res;
    }
    else {
      const dictionary = yield call(() => createDictionaryFromArray<IMemberState>((res as IMemberState[])));
      yield put(addMembersActiveChatAction(dictionary as IMemberDictionary));
      yield put(addMembersStoredChatAction({ chatId: action.payload, members: dictionary as IMemberDictionary }));
    }
  }
  catch (e) {
    console.log(e);
  }
}
