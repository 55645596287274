import { Navigate, Outlet } from 'react-router-dom';
import { FC } from "react";
import { useSelector } from "react-redux";
import { tokensEmailIsConfirmedSelector, tokensAccessSelector } from "../store/slices/tokensSlice";
import { settingsChangeEmailKeySelector, settingsChangePasswordKeySelector } from "../store/slices/settingsSlice";
import { userTypeSelector } from "../store/slices/userSlice";
import { TUserType } from "../models/UserClaims";

type TProps = {
    goTo: string;
    loged?: boolean,
    type?: 'user' | 'merchant'
}

export const RouteGuardByToken: FC<TProps> = ({ loged, goTo }) => {
  const token = !!useSelector(tokensAccessSelector);

  return (token ? <Outlet /> : <Navigate to={goTo} />);
};

export const RouteGuardBydMail: FC<TProps> = ({ goTo }) => {
  const isConfirm = useSelector(tokensEmailIsConfirmedSelector);

  return isConfirm ? <Outlet /> : <Navigate to={goTo} />;
};

export const RouteGuardBydKey: FC<TProps> = ({ goTo }) => {
  const keyPass = useSelector(settingsChangePasswordKeySelector);
  const keyEmail = useSelector(settingsChangeEmailKeySelector);

  return keyPass || keyEmail ? <Outlet /> : <Navigate to={goTo} />;
};

export const RouteBackGuard: FC<TProps> = ({ goTo }) => {
  const isConfirm = useSelector(tokensEmailIsConfirmedSelector);
  const token = !!useSelector(tokensAccessSelector);

  return isConfirm && token ? <Navigate to={goTo} /> : <Outlet />;
};

export const RouteGuardByType: FC<{ type: TUserType, goTo: string }> = ({ type, goTo }) => {
  const userType = useSelector(userTypeSelector);

  return type === userType ? <Outlet /> : <Navigate to={goTo} />;
};
