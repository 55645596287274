import {
  Avatar, Box, Chip, Typography
} from "@mui/material";
import { IChatItem } from "../../../models";
import { FC } from "react";
import './style.scss';
import { getMessageTime } from "../../../helpers";
import { useSelector } from "react-redux";
import { userSelector } from "../../../store/slices/userSlice";

type TProps = {
    item: IChatItem
}

export const ChatPreview: FC<TProps> = ({ item }) => {
  const user = useSelector(userSelector);

  return (
    <Box className="preview-card">
      <Avatar
        alt={item.name}
      >
        {item.name[0].toUpperCase() + item.name[item.name.length - 1]}
      </Avatar>
      <Box className="preview-card-info">
        <Typography className="preview-card-info__label">
          {item.name}
        </Typography>

        {item.latestMessage ?
          <Box className="preview-card-info__desc">
            {
              item.chatType.toLowerCase() !== 'private' &&
                            <Typography className="sender-name">
                              {user.id !== item.latestMessage.sender.id ? `${item.latestMessage.sender.name}: ` : 'You:'}
                            </Typography>
            }
            <Typography>
              {item.latestMessage.text}
            </Typography>
          </Box>
          :
          <Typography className="preview-card-info__desc">
                        Just created
          </Typography>
        }

      </Box>
      <Box className="preview-card__additional">
        {
          item.latestMessage && <Typography className="preview-card__time" >{getMessageTime(item.latestMessage.sentDate, 'time')}</Typography>
        }

        {
          Boolean(item.unread) && <Chip className="preview-card__badge" label={item.unread} color="primary" size="small" />
        }
      </Box>

    </Box>
  );
};
