import { IAction, IHubResponse } from "../../../models";
import {
  call, put, select
} from "@redux-saga/core/effects";
import { hubSendMessage } from "../../../hub";
import { IMemberDictionary, IMessageState } from "../../../models/states/IChatsState";

import { changeLastMessageInChatItem, reorderChatsListAction } from "../../slices/chatSlices/chatsListSlice";
import {
  activeChatMembersSelector,
  activeChatIdSelector,
  addMessagesActiveChatAction, changeActiveChatScrollPosAction,
} from "../../slices/chatSlices/activeChatSlice";
import { messageSliceChangeIsSendingAction } from "../../slices/chatSlices/messageSlice";

export function* sendMessageSaga(action: IAction<any>): Generator {
  try {
    yield put(messageSliceChangeIsSendingAction(true));
    const res = yield call(() => hubSendMessage(action.payload));

    if ((res as any).success && (res as any).data) {
      const activeChatId = yield select(activeChatIdSelector);
      if ((activeChatId as string) === (res as any).data.chatId) {
        let message = yield {
          id: (res as any).data.messageId,
          sentDate: (res as any).data.sentDate,
          senderId: (res as any).data.sender.id,
          text: (res as any).data.text
        };

        yield put(addMessagesActiveChatAction([message as IMessageState]));

        const members = yield select(activeChatMembersSelector);
        const sender = {
          id: (message as IMessageState).senderId,
          name: (members as IMemberDictionary)[(message as IMessageState).senderId].name,
          surname: (members as IMemberDictionary)[(message as IMessageState).senderId].surname,
        };

        // Rewrite message object for another state

        message = Object.assign({ sender, }, message);
        delete (message as any).senderId;

        yield put(changeLastMessageInChatItem({ id: (res as IHubResponse).data.chatId, message, unread: 0, role: 'add' }));
        yield put(reorderChatsListAction((res as IHubResponse).data.chatId));
        yield put(changeActiveChatScrollPosAction(-1));
      }
    }
    else {
      throw res;
    }
  }
  catch (e) {
    console.log(e);
  }
  finally {
    yield put(messageSliceChangeIsSendingAction(false));
  }
}
