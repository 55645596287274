import {
  IAction, IChatItem, IError
} from "../../../models";
import { call, put } from "@redux-saga/core/effects";
import { fetchGetChatItem } from "../../../services/ChatService";
import { changeItemChatsAction } from "../../slices/chatSlices/chatsListSlice";

export function* getChatItemSaga(action: IAction<string>): Generator {
  try {
    const res = yield call(() => fetchGetChatItem(action.payload));
    if ((res as object).hasOwnProperty('errorCode') && (res as IError).errorCode !== 10) {
      throw res;
    }
    else {
      yield put(changeItemChatsAction((res as IChatItem)));
    }
  }
  catch (e) {
    console.log(e);
  }
}
