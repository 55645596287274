import { IAction } from "../../../models";
import { IActiveChats, IMessageState } from "../../../models/states/IChatsState";
import { put, select } from "@redux-saga/core/effects";
import {
  changeLastMessageInChatItem,
  changeUnreadInChatItem,
  reorderChatsListAction
} from "../../slices/chatSlices/chatsListSlice";
import {
  activeChatIdSelector,
  addMessagesActiveChatAction, changeActiveChatScrollPosAction, changeLastActivityAction
} from "../../slices/chatSlices/activeChatSlice";
import {
  addMessageStoredChatAction,
  changeLastActivityStoredChatAction, changeStoredChatIsAllNewAction,
  storedChatsSelector
} from "../../slices/chatSlices/storedChatsSlice";
import { userIdSelector } from "../../slices/userSlice";

export function* messageReceiveMessageSaga(action: IAction<any>): Generator {
  try {
    const userId = yield select(userIdSelector);
    const activeChatId = yield select(activeChatIdSelector);

    console.log(action.payload);
    let message = yield {
      id: action.payload.messageId,
      isModified: false,
      sentDate: action.payload.sentDate,
      senderId: action.payload.sender.id,
      text: action.payload.text,
    };

    if (activeChatId === action.payload.chatId) {
      yield put(addMessagesActiveChatAction([(message as IMessageState)]));

      if (userId === action.payload.sender.id) {
        yield put(changeUnreadInChatItem({ chatId: action.payload.chatId, unread: 0 }));
        yield put(changeActiveChatScrollPosAction(-1));
      }
      else {
        const activity = new Date(action.payload.sentDate).valueOf();

        yield put(changeLastActivityAction({
          activity,
          memberId: action.payload.sender.id,
          isChat: true,
        }));
      }
    }
    else if (activeChatId !== action.payload.chatId) {
      const storedChats = yield select(storedChatsSelector);
      if ((storedChats as IActiveChats).hasOwnProperty(action.payload.chatId)) {
        yield put(addMessageStoredChatAction({
          chatId: action.payload.chatId,
          messages: [message as IMessageState],
        }));

        if (userId !== action.payload.sender.id) {
          yield put(changeLastActivityStoredChatAction({
            activity: new Date(action.payload.sentDate).valueOf(),
            chatId: action.payload.chatId,
            memberId: action.payload.sender.id
          }));
          yield put(changeStoredChatIsAllNewAction({ chatId: action.payload.chatId, isAll: false }));
        }
      }
    }

    message = Object.assign({ sender: action.payload.sender }, message);
    delete (message as any).senderId;
    delete (message as any).sendDate;

    const unread = userId === action.payload.sender.id ? 0 : 1;
    yield put(changeLastMessageInChatItem({ id: action.payload.chatId, message, unread, role: 'add' }));
    yield put(reorderChatsListAction(action.payload.chatId));
  }
  catch (e) {
    console.log(e);
  }
}
