import { IActiveChatState } from "../../../models/states/IChatsState";
import { FC, useState } from "react";
import {
  Avatar, Box, Button, List, ListItem, Typography
} from "@mui/material";
import './style.scss';
import { getMessageTime } from "../../../helpers";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { ContactsList } from "../contacts-list";
import { CustomModal } from "../../modal";
import { useDispatch, useSelector } from "react-redux";
import { addMembersAction, chatContactsSelector } from "../../../store/slices/chatSlices/chatsListSlice";
import { uiChangeMessageAction } from "../../../store/slices/uiSlice";

type TProps = {
    chat: IActiveChatState
}

export const ChatInfo: FC<TProps> = ({ chat }) => {
  const [isShow, setIsShow] = useState(false);
  const contacts = useSelector(chatContactsSelector);
  const dispatch = useDispatch();
  console.log(chat);
  const addMembers = (ids: string[]) => {
    dispatch(uiChangeMessageAction({
      isShow: true,
      title:'',
      desc: `Are you shure you want to add ${ids.length} members \n to ${chat.name}`,
      role: 'acc',
      callback: () => dispatch(addMembersAction(ids))
    }));
  };

  return (
    <Box className="chat-info_container">
      <Box className="chat-info_header">
        <Box className="chat-info_header-title">
          <Avatar
            alt={chat.name}
          >
            {chat.name[0].toUpperCase() + chat.name[chat.name.length - 1]}
          </Avatar>
          <Box>
            <Typography className="chat-info_name">
              {chat.name}
            </Typography>
            <Typography className="chat-info_members">
                               members: {Object.keys(chat.members).length}
            </Typography>
          </Box>
        </Box>
        <Typography className="chat-info_type">
          {chat.chatType}
        </Typography>
      </Box>

      <List className="chat-info_members-list styled-scroll">
        {Object.keys(chat.members).map(el => (
          <ListItem key={el} className="chat-info_members-item">
            <Avatar
              alt={chat.members[el].name}
            >
              {chat.members[el].name[0].toUpperCase() + chat.members[el].surname[0].toUpperCase()}
            </Avatar>

            <Box className="chat-info_members-info">
              <Typography className="chat-info_members-name">
                {`${chat.members[el].name} ${chat.members[el].surname}`}
              </Typography>
              <Typography className="chat-info_members-date">

                                   last seen: {getMessageTime(chat.members[el].lastRead, 'date')}
              </Typography>
            </Box>
          </ListItem>
        ))}
      </List>
      <Box className="chat-info_buttons" onClick={() => setIsShow(true)}>
        {chat.chatType.toLowerCase() !== 'private' &&
                        <Button className="chat-info_button" variant="text" >
                          <Typography>Add members</Typography>
                          <GroupAddIcon />
                        </Button>}
      </Box>
      <CustomModal
        handleShow={() => setIsShow(!isShow)}
        open={isShow}
        children={
          <ContactsList
            items={contacts.filter(el => !chat.members.hasOwnProperty(el.id))}
            role="members"
            members={[]}
            returnList={addMembers}
            handleClose={() => setIsShow(!isShow)} />}
        width={'360px'}/>
    </Box>

  );
};
