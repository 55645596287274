import { put, select } from "@redux-saga/core/effects";

import { IActiveChats } from "../../../models/states/IChatsState";
import {
  changeLastActivityStoredChatAction,
  storedChatsSelector
} from "../../slices/chatSlices/storedChatsSlice";
import {
  activeChatIdSelector,
  changeLastActivityAction
} from "../../slices/chatSlices/activeChatSlice";
import { userIdSelector } from "../../slices/userSlice";
import { changeUnreadInChatItem } from "../../slices/chatSlices/chatsListSlice";

export function* memberReadMessagesSaga(action: any): Generator {
  try {
    const activeChatId = yield select(activeChatIdSelector);
    const userId = yield select(userIdSelector);

    if (action.payload.memberId !== userId) {
      if ((activeChatId as string) === action.payload.chatId) {
        yield put(changeLastActivityAction({
          activity: new Date(action.payload.lastRead).valueOf(),
          memberId: action.payload.memberId,
          isChat: true,
        }));

        yield put(changeLastActivityStoredChatAction({
          activity: new Date(action.payload.lastRead).valueOf(),
          memberId: action.payload.memberId,
          chatId: action.payload.chatId
        }));
      }

      else if ((activeChatId as string) !== action.payload.chatId) {
        const storedChats = yield select(storedChatsSelector);

        if ((storedChats as IActiveChats).hasOwnProperty(action.payload.chatId)) {
          yield put(changeLastActivityStoredChatAction({
            activity: new Date(action.payload.lastRead).valueOf(),
            memberId: action.payload.memberId,
            chatId: action.payload.chatId
          }));
        }
      }
    }
    else {
      yield put(changeUnreadInChatItem({
        chatId: action.payload.chatId,
        unread: action.payload.unread
      }));
    }
  }
  catch (e) {
    console.log(e);
  }
}
