import {
  IAction, IError, ILetterResponse
} from "../../../models";
import { call, put } from "@redux-saga/core/effects";
import {
  errorsSendLetterUpdateAction,
  errorsUiUpdateAction
} from "../../slices/errorSlice";
import { AxiosResponse } from "axios";
import { uiUpdateTimerAction } from "../../slices/uiSlice";
import { fetchPasswordLetter } from "../../../services/PasswordService";
import { fetchEmailLatter } from "../../../services/EmailService";
import { addEmailAction } from "../../slices/settingsSlice";
import { fetchConfirmLatter } from "../../../services/AuthService";

const services = {
  password: fetchPasswordLetter,
  email: fetchEmailLatter,
  confirm:  fetchConfirmLatter,
};

export function* sendLetterSaga(action: IAction<{ email: string, endpoint: "password" | 'email' | 'confirm' }>): Generator<AxiosResponse | any> {
  try {
    yield put(errorsSendLetterUpdateAction(null));
    yield put(errorsUiUpdateAction(null));
    let response: ILetterResponse | IError | unknown;

    response = yield call(
      () => services[action.payload.endpoint]({ email: action.payload.email, accessType: 'Client' }));

    if (!(response as object).hasOwnProperty('errorCode') && (response as IError).errorCode !== 10) {
      const time: number = (response as ILetterResponse).nextAttemptAvailableInSeconds;
      if (time as number > 0) {
        yield put(uiUpdateTimerAction(time as number));
      }
      put(addEmailAction(''));
    }
    else {
      throw response;
    }
  }
  catch (e) {
    if ((e as IError).status) {
      yield put(errorsSendLetterUpdateAction(e as IError));
    }
    else {
      yield put(errorsUiUpdateAction(e as IError));
    }
  }
  finally {

  }
}
