import {
  IActiveChatState,
  IActiveChatStoreState, IMemberDictionary, IMessageState,

} from "../../../models/states/IChatsState";
import { createSlice } from "@reduxjs/toolkit";
import { IAction, IStoreState } from "../../../models";
import { sortMessages } from "../../../helpers";

const initialState: IActiveChatStoreState = {
  chat: {
    id: '',
    chatType: '',
    members: {},
    messages: [],
    allOld: false,
    allNew: false,
    name: '',
    isRequesting: false,
    createdDate: '',
    scrollPos: -1,
    lastActivity: 0,
  },
  isLoading: false
};

const activeChatSlice = createSlice({
  name: 'activeChat',
  initialState,
  reducers: {
    changeActiveChatAction(state: IActiveChatStoreState, action: IAction<IActiveChatState>) {
      state.chat = action.payload;
    },
    addMembersActiveChatAction(state: IActiveChatStoreState, action: IAction<IMemberDictionary>) {
      state.chat.members = action.payload;
    },
    updateMessageActiveChatAction(state: IActiveChatStoreState, action: IAction<{ messageId: string, text: string }>) {
      if (state.chat.messages.findIndex(el => el.id === action.payload.messageId) !== -1) {
        const index = state.chat.messages.findIndex(el => el.id === action.payload.messageId);
        state.chat.messages[index].text = action.payload.text;
        state.chat.messages[index].isModified = true;
      }
    },
    deleteMessageActiveChatAction(state: IActiveChatStoreState, action: IAction<{ messageId: string }>) {
      if (state.chat.messages) {
        state.chat.messages = state.chat.messages.filter(el => el.id !== action.payload.messageId);
      }
    },
    addMessagesActiveChatAction(state: IActiveChatStoreState, action: IAction<IMessageState[]>) {
      if (state.chat.messages && action.payload.length > 1) {
        state.chat.messages = sortMessages(state.chat.messages.concat(action.payload).flat());
      }
      else {
        state.chat.messages = state.chat.messages.concat(action.payload).flat();
      }
    },
    changeActiveChatIsLoadAction(state: IActiveChatStoreState, action: IAction<boolean>) {
      state.isLoading = action.payload;
    },
    changeActiveChatScrollPosAction(state: IActiveChatStoreState, action: IAction<number>) {
      state.chat.scrollPos = action.payload;
    },
    changeActiveChatIsAllNewAction(state: IActiveChatStoreState, action: IAction<boolean>) {
      state.chat.allNew = action.payload;
    },
    changeActiveChatIsAllOldAction(state: IActiveChatStoreState, action: IAction<boolean>) {
      state.chat.allOld = action.payload;
    },
    changeActiveChatIsReqAction(state: IActiveChatStoreState, action: IAction<boolean>) {
      state.chat.isRequesting = action.payload;
    },
    changeLastActivityAction(state: IActiveChatStoreState, action: IAction<{ activity: number, memberId: string, isChat: boolean }>) {
      if (action.payload.isChat) {
        state.chat.lastActivity = action.payload.activity;
      }
      state.chat.members[action.payload.memberId].lastRead = new Date(action.payload.activity).toISOString();
    },
    clearActiveChatState(state: IActiveChatStoreState) {
      state.chat = {
        id: '',
        chatType: '',
        members: {},
        messages: [],
        allOld: false,
        allNew: false,
        name: '',
        isRequesting: false,
        createdDate: '',
        scrollPos: -1,
        lastActivity: 0
      };
      state.isLoading = false;
    },

  }
});

export const activeChatReducer = activeChatSlice.reducer;

export const {
  changeActiveChatAction,
  addMembersActiveChatAction,
  addMessagesActiveChatAction,
  changeActiveChatIsLoadAction,
  changeActiveChatIsAllNewAction,
  changeActiveChatIsAllOldAction,
  changeActiveChatScrollPosAction,
  clearActiveChatState,
  changeActiveChatIsReqAction,
  changeLastActivityAction,
  updateMessageActiveChatAction,
  deleteMessageActiveChatAction,
} = activeChatSlice.actions;

export const activeChatSelector = (state: IStoreState) => state.activeChat.chat;
export const activeChatIdSelector = (state: IStoreState) => state.activeChat.chat.id;
export const activeChatMessagesSelector = (state: IStoreState) => state.activeChat.chat.messages;
export const activeChatIsLoadingSelector = (state: IStoreState) => state.activeChat.isLoading;
export const activeChatMembersSelector = (state: IStoreState) => state.activeChat.chat.members;
export const activeChatScrollPosSelector = (state: IStoreState) => state.activeChat.chat.scrollPos;
export const activeChatIsAllNewSelector = (state: IStoreState) => state.activeChat.chat.allNew;
export const activeChatIsAllOldSelector = (state: IStoreState) => state.activeChat.chat.allOld;
export const activeChatIsReqSelector = (state: IStoreState) => state.activeChat.chat.isRequesting;
export const lastChatActivitySelector = (state: IStoreState) => state.activeChat.chat.lastActivity;
