import {
  IChat,
  IChatItem,
  IChatsListState,
} from "../../../models/states/IChatsState";
import { createAction, createSlice } from "@reduxjs/toolkit";
import {
  IAction, IStoreState, IUserState
} from "../../../models";
import { sortChatList } from "../../../helpers";

const initialState: IChatsListState = {
  chats: null,
  contacts: [],
  tmplChat: null,
  isLoadActiveChat: false,
  isLoadChats: false,
};

const chatsListSlice = createSlice({
  name: 'chatsList',
  initialState,
  reducers: {
    addChatContactsAction(state: IChatsListState, action: IAction<IUserState[]>) {
      state.contacts = action.payload;
    },
    addChatsAction(state: IChatsListState, action: IAction<IChatItem[]>) {
      state.chats = !state.chats ? action.payload : state.chats.concat(action.payload).flat();
    },
    changeItemChatsAction(state: IChatsListState, action: IAction<IChatItem>) {
      if (state.chats && state.chats?.findIndex(el => el.id === action.payload.id) !== -1) {
        const i = state.chats?.findIndex(el => el.id === action.payload.id);
        state.chats[i] = action.payload;
      }
    },
    changeLastMessageInChatItem(state: IChatsListState, action: IAction<{ id: string, message: any, unread: number, role: 'add' | 'upd' }>) {
      if (state.chats) {
        const item = state.chats.find(el => el.id === action.payload.id);
        if (item) {
          if (action.payload.role === 'add') {
            item.latestMessage = action.payload.message;
          }
          else if (action.payload.role === 'upd') {
            if (item.latestMessage) {
              item.latestMessage.text = action.payload.message;
            }
          }
          item.unread += action.payload.unread;
        }
      }
    },
    changeUnreadInChatItem(state: IChatsListState, action: IAction<{ chatId: string, unread: number }>) {
      if (state.chats) {
        const chat = state.chats.find(el => el.id === action.payload.chatId);
        if (chat) {
          chat.unread = action.payload.unread;
        }
      }
    },
    reorderChatsListAction(state: IChatsListState, action: IAction<string>) {
      state.chats = sortChatList(state.chats, action.payload);
    },
    changeIsLoadChatsAction(state: IChatsListState, action: IAction<boolean>) {
      state.isLoadChats = action.payload;
    },
    addTmplChatAction(state: IChatsListState, action: IAction<IChat | null>) {
      state.tmplChat = action.payload;
    },
    clearChatsListState(state: IChatsListState) {
      state.chats = null;
      state.contacts = [];
      state.tmplChat = null;
      state.isLoadActiveChat = false;
      state.isLoadChats = false;
    }
  }
});

export const chatsListReducer = chatsListSlice.reducer;
//Actions

export const {
  addChatsAction,
  changeIsLoadChatsAction,
  addTmplChatAction,
  addChatContactsAction,
  clearChatsListState,
  changeLastMessageInChatItem,
  reorderChatsListAction,
  changeItemChatsAction,
  changeUnreadInChatItem,
} = chatsListSlice.actions;

export const CREATE_CHAT = 'chatsListSlice/createChatAction';
export const createChatAction = createAction(CREATE_CHAT);

export const GET_CONTACTS = 'chatsListSlice/getContactsAction';
export const getContactsAction = createAction(GET_CONTACTS);

export const GET_CHATS = 'chatsListSlice/getChatsAction';
export const getChatsAction = createAction(GET_CHATS);

export const GET_CHATS_ITEM = 'chatsListSlice/getChatsItemAction';
export const getChatsItemAction = createAction(GET_CHATS_ITEM, payload => ({ payload }));

export const GET_CHAT = 'chatsListSlice/getChatAction';
export const getChatAction = createAction(GET_CHAT, (payload: { unread: number, name: string, id: string }) => ({ payload }));

export const GET_MESSAGES = 'chatsListSlice/getMessageAction';
export const getMessagesAction = createAction(GET_MESSAGES, payload => ({ payload }));

export const ADD_MEMBERS = 'chatsListSlice/addMembersAction';
export const addMembersAction = createAction(ADD_MEMBERS, payload => ({ payload }));

export const GET_MEMBERS = 'chatsListSlice/getMembersAction';
export const getMembersAction = createAction(GET_MEMBERS, payload => ({ payload }));

//Selectors
export const chatsSelector = (state: IStoreState) => state.chatsList.chats;
export const chatItemSelector = (state: IStoreState, chatId: string) => state.chatsList.chats?.filter(el => el.id === chatId)[0];
export const chatsLoaderSelector = (state: IStoreState) => state.chatsList.isLoadChats;
export const chatContactsSelector = (state: IStoreState) => state.chatsList.contacts;
export const tmplChatSelector = (state: IStoreState) => state.chatsList.tmplChat;
