import { AxiosResponse } from "axios";
import { IError } from "../models";
import $api from "../http";
import { transformError } from "../helpers";
import { IUserDetailsBody } from "../models";
import { checkResponse } from "./checkResponse";

const userDetailsPath: string = `/user/details`;

export const fetchAddUserDetails = async (data: IUserDetailsBody): Promise<AxiosResponse<any> | IError> => {
  return $api.post(userDetailsPath, data)
    .then(res => checkResponse(res))
    .catch(e => transformError(e));
};

export const fetchGetUserDetails = async (): Promise<AxiosResponse<IUserDetailsBody> | IError> => {
  return $api.get(userDetailsPath)
    .then(res => checkResponse(res))
    .catch(e => transformError(e));
};

