import { HubConnectionBuilder } from "@microsoft/signalr";
import { environment } from "../environments/environment";
import { IChat, IStore } from "../models";
import {
  addChatsAction, getMembersAction, reorderChatsListAction
} from "../store/slices/chatSlices/chatsListSlice";
import {
  hubDeleteMessagesAction,
  hubUpdateMessagesAction, memberReadMessageAction,
  receiveMessagesAction
} from "../store/slices/chatSlices/messageSlice";

let store: IStore;

export const injectStoreToHub = (_store: IStore) => {
  store = _store;
};

export const $hubConnection = new HubConnectionBuilder()
  .withUrl(`${environment.apiUrl}/hubs/core`)
  .withAutomaticReconnect()
  .build();

$hubConnection.on('MessageReceive', (data) => {
  store.dispatch(receiveMessagesAction(data));
});
$hubConnection.on('MessageUpdate', (data) => {
  store.dispatch(hubUpdateMessagesAction(data));
});
$hubConnection.on('MessageDelete', (data) => {
  store.dispatch(hubDeleteMessagesAction(data));
});
$hubConnection.on('ChatJoin', (data) => {
  console.log(data);
  store.dispatch(addChatsAction([data]));
  store.dispatch(reorderChatsListAction(data.id));
});
$hubConnection.on('MessageRead', (data) => {
  store.dispatch(memberReadMessageAction(data));
});
$hubConnection.on('MembersAdded', (data) => {
  store.dispatch(getMembersAction(data));
});

export const hubStartConnection = async () => {
  try {
    await $hubConnection.start();
  }
  catch (e) {
    console.log(e);
  }
};

export const hubCreateChat = async (data: IChat) => {
  const req = await $hubConnection.invoke('CreateChat', data);

  return req;
};

export const hubSendMessage = async (data: IChat) => {
  return await $hubConnection.invoke('SendMessage', data);
};

export const hubDeleteMessage = async (data: { messageId: string, chatId: string }) => {
  return await $hubConnection.invoke('DeleteMessage', data);
};
export const hubAddMembers = async (data: { chatId: string, members: string[] }) => {
  return await $hubConnection.invoke('AddChatMembers', data);
};

export const hubReadMessages = async (data: any) => {
  return await $hubConnection.invoke('ReadMessages', data);
};

export const hubUpdateMessages = async (data: { messageId: string, chatId: string, text: string }) => {
  return await $hubConnection.invoke('UpdateMessage', data);
};

export const hubCloseConnection = async () => {
  try {
    await $hubConnection.stop().then(res => console.log(res));
  }
  catch (e) {
    console.log(e);
  }
};
