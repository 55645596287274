import { FC, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Link,
  TextField,
  Typography
} from "@mui/material";
import { Copyright, PasswordInput } from "../../index";
import './style.scss';
import { validation } from "../../../schemas/validation.schemas";
import { useDispatch, useSelector } from "react-redux";
import {
  signInAction,
  signInStoreSelector
} from "../../../store/slices/signInSlice";
import { FORGOT_PASSWORD, SIGN_UP } from "../../../routes/paths";
import { errorsSignInSelector } from "../../../store/slices/errorSlice";
import { useFormikRef, useClearErrorsNavigate } from "../../../hooks";
import { setError } from "../../../helpers/setError";

export const FormSignIn: FC = () => {
  const navigate = useClearErrorsNavigate();
  const signInValues = useSelector(signInStoreSelector);
  const error = useSelector(errorsSignInSelector);

  const dispatch = useDispatch();

  const onSubmit = (values: { email: string, password: string, rememberMe: boolean, accessType: 'Admin' }) => {
    dispatch(signInAction(values));
  };

  const formikRef = useFormikRef(
    signInValues,
    validation.signIn(),
    onSubmit,
  );

  useEffect(() => {
    if (error) {
      setError(formikRef.current, error, 'signIn');
    }

    return;
  }, [error, formikRef]);

  const formik = formikRef.current;

  return (
    <Box sx={{ maxWidth: '300px', mt:10, marginLeft: 'auto', marginRight: 'auto' }}>
      <Typography fontSize="xx-large" component="h1" variant="h5">
                Sign in
      </Typography>
      <Box className="form" component="form" sx={{ mt: 1 }} onSubmit={formik.handleSubmit}>
        <TextField
          margin="normal"
          id="outlined-basic"
          label="Email"
          variant="outlined"
          type="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!formik.errors?.email}
          helperText={formik.errors?.email as string}
        />

        <PasswordInput
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={ !!formik.errors?.password}
          helperText={formik.errors?.password as string}
        />

        <FormControlLabel
          sx={{ mt: '1rem', mb: '1rem' }}
          control={<Checkbox onClick={formik.handleChange} color="primary" name="rememberMe" value={formik.values.rememberMe} />}
          label="Remember me"
        />

        <Divider sx={{ border: '1px solid #000', opacity: '0.25', mb: '1rem' }} variant="middle" />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Button variant="contained" type="submit" >Sign in</Button>
          <Link className={'form__signup-link'} onClick={() => navigate(SIGN_UP)}>Sign Up</Link>
        </Box>
        <Link className="forgot-link" onClick={() => navigate(FORGOT_PASSWORD)} >Forgot password?</Link>
      </Box>
      <Copyright />
    </Box>
  );
};
export default FormSignIn;
