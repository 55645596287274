import {
  call, put, select
} from "@redux-saga/core/effects";
import {
  addChatsAction,
  addTmplChatAction, getChatAction, reorderChatsListAction,
  tmplChatSelector
} from "../../slices/chatSlices/chatsListSlice";
import { hubCreateChat } from "../../../hub";
import {
  IChat, IChatItem, IError, IMemberState
} from "../../../models";
import { uiChangeMessageAction } from "../../slices/uiSlice";
import { IActiveChats, IMemberDictionary } from "../../../models/states/IChatsState";
import { addMembersActiveChatAction, changeActiveChatAction } from "../../slices/chatSlices/activeChatSlice";
import {
  addChatStoredChatsAction,
  addMembersStoredChatAction,
  storedChatsSelector
} from "../../slices/chatSlices/storedChatsSlice";
import { fetchGetChatItem } from "../../../services/ChatService";
import { createDictionaryFromArray } from "../../../helpers";

export function* createChatSaga(): Generator {
  try {
    const data = yield select(tmplChatSelector);
    if (data) {
      const res: any = yield call(() => hubCreateChat((data as IChat)));
      if (res.success && res.data) {
        const chatItem = yield call(() => fetchGetChatItem(res.data.id));
        yield put(addChatsAction([chatItem as IChatItem]));
        yield put(addTmplChatAction(null));

        const dictionary = yield call(() => createDictionaryFromArray<IMemberState>((res.data.members as IMemberState[])));
        console.log(res.data);
        yield put(changeActiveChatAction(res.data));
        yield put(addChatStoredChatsAction(res.data));

        yield put(addMembersActiveChatAction(dictionary as IMemberDictionary));
        yield put(addMembersStoredChatAction({ chatId: res.data.id, members: dictionary as IMemberDictionary }));

        yield put(reorderChatsListAction(res.data.id));
        yield put(uiChangeMessageAction({
          isShow: true,
          title: 'Created!',
          desc: `Chat "${(res.data as IChat).name}" was created!`,
          role: 'inf'
        }));
      }
      else {
        res.chatType = (data as IChat).chatType;
        res.name = (data as IChat).name;
        throw res;
      }
    }
  }
  catch (e) {
    if (((e as IError).messages.Members && (e as any).chatType.toLowerCase() === 'private') && Array.isArray((e as IError).messages.ChatId)) {
      const chatId = ((e as IError).messages.ChatId as string[])[0];
      const downChats = yield select(storedChatsSelector);
      if ((downChats as IActiveChats).hasOwnProperty((chatId as string))) {
        yield changeActiveChatAction((downChats as IActiveChats)[chatId]);
      }
      else {
        yield put(getChatAction({ id: chatId, name: (e as IChat).name, unread: 0 }));
      }
    }
    else {
      console.log(e);
    }
  }
}
