import {
  Box, Collapse, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText
} from "@mui/material";
import { FC, useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../store/slices/tokensSlice";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import { SettingsList } from "../settings-list";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  FILES, HOME, MERCHANT
} from "../../routes/paths";
import { userTypeSelector } from "../../store/slices/userSlice";
import { useClearErrorsNavigate } from "../../hooks";
import { uiChangeMessageAction } from "../../store/slices/uiSlice";
type TProps = {
    showMenu: (b: boolean) => void;
}

export const MenuList: FC<TProps> = ({ showMenu }) => {
  const dispatch = useDispatch();
  const navigate = useClearErrorsNavigate();
  const userType = useSelector(userTypeSelector);
  const [expand, setExpand] = useState(false);
  const handleLogout = () => {
    dispatch(uiChangeMessageAction({
      isShow: true,
      title: ':(',
      desc: 'Are you sure that you wanna leave?',
      role: 'acc',
      callback: () => dispatch(logoutAction())
    }));
  };

  const handleClick = (route: string) => {
    showMenu(false);
    navigate(route);
  };

  return (
    <Box>
      <List
        sx={{ width: '300px', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItem>
          <ListItemButton onClick={() => handleClick(HOME)}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText>
                            Home
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={() => handleClick(FILES)}>
            <ListItemIcon>
              <FileDownloadIcon />
            </ListItemIcon>
            <ListItemText>
                            Files
            </ListItemText>
          </ListItemButton>
        </ListItem>

        {
          userType === 'Merchant' ?
            <ListItem>
              <ListItemButton onClick={() => handleClick(MERCHANT)}>
                <ListItemIcon>
                  <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText>
                                Shop
                </ListItemText>
              </ListItemButton>
            </ListItem> : null
        }

        <ListItem sx={{ display: 'block' }}>
          <ListItemButton onClick={() => setExpand(!expand)}>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Settings" />
            {expand ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={expand} timeout="auto" unmountOnExit>
            <SettingsList showMenu={showMenu} />
          </Collapse>
        </ListItem>

        <Divider></Divider>
        <ListItem>
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
                            Logout
            </ListItemText>
          </ListItemButton>
        </ListItem>

      </List>
    </Box>
  );
};
