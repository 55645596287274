import { FC } from 'react';
import { Typography } from "@mui/material";

export const Copyright: FC = () => {
  return (
    <Typography sx={{ mt: 10 }} variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};
export default Copyright;
