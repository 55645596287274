import {
  call, put, select
} from "@redux-saga/core/effects";
import {
  addChangeKeyPasswordAction,
  addChangeNewPasswordAction,
  settingsChangePasswordSelector
} from "../../slices/settingsSlice";
import { errorsChangePasswordUpdateAction, errorsUiUpdateAction } from "../../slices/errorSlice";
import { uiChangeLoadingAction } from "../../slices/uiSlice";
import { fetchPasswordChange } from "../../../services/PasswordService";
import { IChangePasswordBody, IError } from "../../../models";
import { Router } from "@remix-run/router";
import { getContext } from "redux-saga/effects";
import { SIGN_IN } from "../../../routes/paths";

export function* changePasswordSaga(): Generator {
  try {
    yield put(errorsUiUpdateAction(null));
    yield put(errorsChangePasswordUpdateAction(null));
    yield put(uiChangeLoadingAction(true));

    const data: IChangePasswordBody | unknown = yield select(settingsChangePasswordSelector);

    const resp = yield call(() => fetchPasswordChange((data as IChangePasswordBody)));

    if ((resp as object).hasOwnProperty('errorCode')) {
      throw resp;
    }
    else {
      yield put(addChangeNewPasswordAction(''));
      yield put(addChangeKeyPasswordAction(''));

      const router: Router | unknown = yield getContext('router');
      yield (router as Router).navigate(SIGN_IN, { replace: true });
      alert('Password changed!');
    }
  }
  catch (e) {
    if ((e as IError).status) {
      yield put(errorsChangePasswordUpdateAction((e as IError)));
    }
    else {
      yield put(errorsUiUpdateAction((e as IError)));
    }
  }
  finally {
    yield put(uiChangeLoadingAction(false));
  }
}
