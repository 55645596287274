import {
  FC, useEffect, useState
} from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import './style.scss';
import { errorsUiSelector, errorsUiUpdateAction } from "../../store/slices/errorSlice";

export const ErrorBanner: FC = () => {
  const error = useSelector(errorsUiSelector);
  const [show, setShow] = useState(false);
  const [errorsMessage, setErrorsMessage] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (error && error.messages) {
      const key: string = Object.keys(error.messages)[0];
      // @ts-ignore
      setErrorsMessage(error.messages[key]);
      setShow(true);
    }
    setTimeout(() => {
      setShow(false);
      dispatch(errorsUiUpdateAction(null));
    }, 2000);
  }, [error, errorsMessage, dispatch]);

  return (
    <Box className={ show ? 'error-banner show' : 'error-banner' }>
      {errorsMessage}
    </Box>
  );
};

export default ErrorBanner;
