import { AxiosResponse } from "axios";
import { IError } from "../models";
import $api from "../http";
import { transformError } from "../helpers";
import { checkResponse } from "./checkResponse";

const refreshPath: string = `/auth/refresh`;

export const fetchRefreshToken = (data: string): Promise<AxiosResponse<any> | IError> => {
  return $api.post(refreshPath, { token: data }, {
    headers: {
      'ROOT-REFRESH': ''
    }
  })
    .then(res => checkResponse(res))
    .catch(e => transformError(e));
};

