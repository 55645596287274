import {
  IError, IUpdatePasswordBody, ISignInResponse
} from "../../../models";
import {
  put, call, select
} from "@redux-saga/core/effects";
import { uiChangeLoadingAction, uiChangeMessageAction } from "../../slices/uiSlice";
import { fetchPasswordUpdate } from "../../../services/PasswordService";
import { AxiosResponse } from "axios";
import { errorsUiUpdateAction, errorsUpdatePasswordUpdateAction } from "../../slices/errorSlice";
import {
  addUpdateCurrPasswordAction,
  addUpdateNewPasswordAction,
  settingsUpdatePasswordSelector
} from "../../slices/settingsSlice";

export function* updatePasswordSaga(): Generator {
  try {
    yield put(uiChangeLoadingAction(true));
    yield put(errorsUpdatePasswordUpdateAction(null));
    yield put(errorsUiUpdateAction(null));

    const data: IUpdatePasswordBody | unknown = yield select(settingsUpdatePasswordSelector);
    const resp: AxiosResponse<ISignInResponse> | IError | unknown = yield call(() => fetchPasswordUpdate((data as IUpdatePasswordBody)));

    if ((resp as object).hasOwnProperty('errorCode') && (resp as IError).errorCode !== 10) {
      throw resp;
    }

    yield put(addUpdateCurrPasswordAction(''));
    yield put(addUpdateNewPasswordAction(''));
    yield put(uiChangeMessageAction({
      isShow: true,
      title: 'Updated!',
      desc: `Congratulations you successfully updated your password, please use them for sign in`,
      role: 'inf'
    }));
  }
  catch (e) {
    console.log(e);
    if ((e as IError).status) {
      yield put(errorsUpdatePasswordUpdateAction(e as IError));
    }
    else {
      yield put(errorsUiUpdateAction(e as IError));
    }
  }
  finally {
    yield put(uiChangeLoadingAction(false));
  }
}
