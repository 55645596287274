import {
  Box, Divider, TextField, Typography
} from "@mui/material";
import { validation } from "../../../schemas/validation.schemas";
import ButtonWithTimer from "../../buttonWithTimer";
import { useSelector } from "react-redux";
import './style.scss';
import InfoIcon from "@mui/icons-material/Info";
import {
  FC, memo, useEffect, useState
} from "react";
import { errorsSendLetterSelector } from "../../../store/slices/errorSlice";
import { useFormikRef } from "../../../hooks";
import { setError } from "../../../helpers/setError";

type TProps = {
    action: (value: { email: string }) => void,
    email: string,
    title: string,
    message: string,
}

export const FormSendLetter: FC<TProps> = memo(({ action, email, title, message }) => {
  const error = useSelector(errorsSendLetterSelector);
  const onSubmit = (values: { email: string }) => {
    action({ email: values.email });
  };

  const [emailVal, setEmailVal] = useState(email);

  useEffect(() => {
    setEmailVal(email);
  }, [email]);

  const formikRef = useFormikRef(
    { email: emailVal },
    validation.email(),
    onSubmit,
  );

  const formik = formikRef.current;

  useEffect(() => {
    if (error) {
      setError(formikRef.current, error, 'sendLetter');
    }

    return;
  }, [error, formikRef]);

  return (
    <Box className="reset-form__wrapper">
      <Typography fontSize="xx-large" component="h1" variant="h5">
        {title}
      </Typography>
      <Box className="reset-form" component="form" onSubmit={formik.handleSubmit}>
        <Typography className="reset-form__info">
          <InfoIcon sx={{ float: 'left', marginRight: '3px' }} fontSize="medium"></InfoIcon>
          {message}
        </Typography>
        <TextField
          id="outlined-basic"
          label="Email"
          variant="outlined"
          type="text"
          name="email"
          placeholder={'Email'}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors?.email)}
          helperText={formik.errors.email as string}
        />

        <Divider sx={{ border: '1px solid #000', opacity: '0.25' }} variant="middle" />
        <ButtonWithTimer type="submit" variant="contained" text="Send"/>
      </Box>
    </Box>
  );
});
