import { useDispatch, useSelector } from "react-redux";
import { errorsChangeEmailSelector } from "../../../store/slices/errorSlice";
import {
  addChangeEmailPasswordAction,
  changeEmailAction,
  settingsChangePasswordSelector
} from "../../../store/slices/settingsSlice";
import { validation } from "../../../schemas/validation.schemas";
import { useEffect } from "react";
import {
  Box, Button, Divider, Typography
} from "@mui/material";
import { PasswordInput } from "../../password-input";
import InfoIcon from "@mui/icons-material/Info";
import { useFormikRef } from "../../../hooks/useFormikRef";
import { setError } from "../../../helpers/setError";

export const FormChangeEmail = () => {
  const dispatch = useDispatch();
  const error = useSelector(errorsChangeEmailSelector);
  const changeState = useSelector(settingsChangePasswordSelector);

  const onSubmit = (values: { password: string }) => {
    dispatch(addChangeEmailPasswordAction(values.password));
    dispatch(changeEmailAction());
  };

  const formikRef = useFormikRef(
    { password: changeState.password },
    validation.password(),
    onSubmit,
  );
  const formik = formikRef.current;

  useEffect(() => {
    if (error) {
      setError(formikRef.current, error, 'changeEmail');
    }

    return;
  }, [error, formikRef]);

  return (

    <Box sx={{ maxWidth: '300px', mt:10, marginLeft: 'auto', marginRight: 'auto' }}>

      <Typography fontSize="xx-large" component="h1" variant="h5">
                Change Email
      </Typography>

      <Box className="change-email-form" component="form" sx={{ mt: 1 }} onSubmit={formik.handleSubmit}>
        <Typography className="reset-form__info">
          <InfoIcon sx={{ float: 'left', marginRight: '3px' }} fontSize="medium"></InfoIcon>
                    Please enter your password and your email will be changed
        </Typography>
        <PasswordInput
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors?.password)}
          helperText={formik.errors?.password as string}
        />

        <Divider sx={{ border: '1px solid #000', opacity: '0.25', mb: '1rem' }} variant="middle" />
        <Button fullWidth color="success" variant="contained" type="submit" >Change</Button>
      </Box>
    </Box>
  );
};
