import { createBrowserRouter } from "react-router-dom";
import {
  RouteBackGuard,
  RouteGuardBydMail,
  RouteGuardByToken,
} from "./guardianRoute";
import {
  ACTION, CONFIRM, REDIRECT, SIGN_IN
} from "./paths";

import { ActionRedirectRoute, RedirectRoute } from "./redirectRoutes";
import { Layout } from "../components/layout";
import { auth_routes, main_routes } from "./routes";
import { lazy } from "react";
const ConfirmPage = lazy(() => import('../pages/confirm-page'));

export const router = createBrowserRouter(
  [
    {

      element: <RouteBackGuard goTo={REDIRECT}/>,
      children: auth_routes,

    },
    {
      element: <RouteGuardByToken loged={true} goTo={SIGN_IN} />,
      children: [

        {
          path: CONFIRM,
          element: <Layout children={<ConfirmPage />}/>,
        },
        {
          element: <RouteGuardBydMail goTo={CONFIRM} />,
          children: main_routes

        },

      ]
    },
    {
      path: ACTION,
      element: <ActionRedirectRoute/>
    },
    {
      path: REDIRECT,
      element: <RedirectRoute/>
    },

  ], {
    basename: "",

  },
);

