import {
  Box, Button, TextField, Typography
} from "@mui/material";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../../store/slices/userSlice";
import {
  addTmplChatAction,
  chatContactsSelector,
  createChatAction,
  getContactsAction
} from "../../../store/slices/chatSlices/chatsListSlice";
import { CustomModal } from "../../modal";
import { ContactsList } from "../../chat";
import './style.scss';

type TProps = {
    closeForm?: () => void
}

export const FormCreateChat: FC<TProps> = ({ closeForm }) => {
  const user = useSelector(userSelector);
  const contacts = useSelector(chatContactsSelector);
  const [nameChat, setName] = useState('');
  const [chatMembers, setMembers] = useState<string[]>([]);
  const [showContacts, setShowContacts] = useState(false);
  const dispatch = useDispatch();

  const createChat = (e: any) => {
    e.preventDefault();
    const chatData = {
      chatType: 'group',
      name: nameChat, // string
      members: chatMembers.concat(user.id) // [user.id],
    };

    dispatch(addTmplChatAction(chatData));
    dispatch(createChatAction());

    if (closeForm) {
      closeForm();
    }
  };

  const addMembers = () => {
    dispatch(getContactsAction());
    setShowContacts(true);
  };

  return (
    <>
      <CustomModal
        handleShow={() => setShowContacts(!showContacts)}
        open={showContacts}
        children={<ContactsList
          items={contacts.filter(el => el.id !== user.id)}
          role="members"
          members={chatMembers}
          returnList={setMembers}
          handleClose={() => setShowContacts(!showContacts)} />}
        width={'360px'}/>

      <Box className="form-wrap">
        <Box className="form" component="form" sx={{ mt: 1 }} onSubmit={createChat}>
          <TextField
            margin="normal"
            id="outlined-basic"
            value={nameChat}
            onChange={(e) => setName(e.target.value)}
            label="Chat name"
            variant="outlined"
            type="text"
            name="name"
          />

          <Box sx={{ display: 'flex', flexDirection:'column', alignItems: 'center' }}>
            <Button onClick={addMembers} sx={{ gap: 1 }} variant="text" >
              <Typography>Add members</Typography>
              <GroupAddIcon />
            </Button>
            {
              chatMembers.length > 0 && <Typography sx={{ fontSize: '12px', textAlign: 'center' }}>Added {chatMembers.length} members</Typography>
            }
          </Box>

          <Box className="form__btns">
            <Button variant="text" onClick={closeForm}>Cancel</Button>
            <Button variant="contained" type="submit" disabled={nameChat.length < 1} >Create</Button>
          </Box>
        </Box>
      </Box>
    </>

  );
};
