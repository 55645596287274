import {
  FC, useEffect, useState
} from "react";
import {
  Box, Button, Divider, TextField, Typography
} from "@mui/material";
import { Copyright } from "../../index";
import { ButtonWithTimer } from "../../buttonWithTimer";
import { useDispatch, useSelector } from "react-redux";
import { updateConfirmAction } from "../../../store/slices/tokensSlice";
import { validation } from "../../../schemas/validation.schemas";
import { userSelector } from "../../../store/slices/userSlice";
import {
  errorsConfirmEmailSelector,
  errorsSendLetterSelector
} from "../../../store/slices/errorSlice";
import { sendLetterAction } from "../../../store/slices/settingsSlice";
import { useFormikRef } from "../../../hooks/useFormikRef";
import { setError } from "../../../helpers/setError";

export const FormConfirmEmail: FC = () => {
  const dispatch = useDispatch();

  const email = useSelector(userSelector).email;
  const error = useSelector(errorsConfirmEmailSelector);
  const errorLatter = useSelector(errorsSendLetterSelector);
  const [isError, setIsError] = useState(false);

  const onSubmit = (values: { key: string }) => {
    setIsError(false);
    dispatch(updateConfirmAction(values));
  };

  const formikRef = useFormikRef(
    { key: '' },
    validation.confirmEmail(),
    onSubmit,
  );
  const formik = formikRef.current;

  const makeResend = () => {
    dispatch(sendLetterAction({ email: email, endpoint: 'confirm' }));
  };

  useEffect(() => {
    if (error) {
      setError(formikRef.current, error, 'confirmEmail');
      setIsError(true);
    }

    return;
  }, [error, formikRef, errorLatter]);

  return (
    <Box sx={{ maxWidth: '300px', mt:10, marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
      <Typography fontSize="xx-large" component="h1" variant="h5">
                Confirm your email
      </Typography>
      <Box className="form" component="form" sx={{ mt: 1 }} onSubmit={formik.handleSubmit}>
        <Typography>
                    We sent a code of activation on {email} <br/>
                    Please enter your code
        </Typography>
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          type="text"
          name="key"
          placeholder={'XXXXXXX'}
          value={formik.values.key}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors?.key) || isError}
          helperText={formik.errors?.key as string}
        />

        <Divider sx={{ border: '1px solid #000', opacity: '0.25', mb: '1rem' }} variant="middle" />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Button color="success" disabled={formik.values.key.length <= 0 } variant="contained" type="submit">Verify</Button>
          <Typography> If you didn't get a code you can resend after: </Typography>
          <ButtonWithTimer text="Resend" makeResend={makeResend}/>
        </Box>
      </Box>
      <Copyright />
    </Box>
  );
};
export default FormConfirmEmail;
