import { takeEvery, takeLatest } from 'redux-saga/effects';

// *SAGAS
import { writeTokensSaga, updateTokenSaga } from "./token-sagas";
import { logoOutSaga } from "./logoOutSaga";

import {
  fetchFileSaga,
  deleteFileSaga,
  getFilesListSaga,
  handleUploadFilesSaga
} from "./files-sagas";

import {
  signUpSaga,
  signInSaga,
  confirmEmailSaga,
  changePasswordSaga
} from "./auth-sagas";

import {
  updateUserDetailsSaga,
  updatePasswordSaga,
  sendLetterSaga,
  getUserDetailsSaga,
  changeEmailSaga,
} from "./user-sagas";

import {
  createChatSaga,
  getChatSaga,
  getChatsSaga,
  getContactsSaga,
  sendMessageSaga,
  deleteMessageSaga,
  getChatItemSaga,
  updateMessageSaga,
  messageReceiveMessageSaga,
  hubUpdateMessageSaga,
  hubDeleteMessageSaga, addMembersSaga, getMembersSaga, userReadMessageSaga, memberReadMessagesSaga,
} from "./chatsSagas";

// TYPES
import {
  ADD_MEMBERS,
  CREATE_CHAT,
  GET_CHAT,
  GET_CHATS, GET_CHATS_ITEM,
  GET_CONTACTS, GET_MEMBERS,
  GET_MESSAGES,
} from "../slices/chatSlices/chatsListSlice";
import {
  LOGOUT, UPDATE_TOKENS, UPDATE_CONFIRM, WRITE_TOKENS
} from "../slices/tokensSlice";
import {
  DELETE_FILE, ADD_UPLOAD_FILES, GET_FILES, UPLOAD_FILES
} from '../slices/filesSlice';
import {
  CHANGE_EMAIL, CHANGE_PASSWORD, SEND_LETTER, UPDATE_PASSWORD
} from "../slices/settingsSlice";
import { SIGN_UP } from "../slices/signUpSlice";
import { SIGN_IN } from "../slices/signInSlice";
import { GET_DETAILS, UPDATE_DETAILS } from "../slices/userSlice";
import { getMessagesSaga } from "./chatsSagas/getMessagesSaga";
import {
  DELETE_MESSAGE, HUB_DELETE_MESSAGE,
  HUB_UPDATE_MESSAGE,
  MEMBER_READ_MESSAGE,
  RECEIVE_MESSAGE,
  SEND_MESSAGE, UPDATE_MESSAGE,
  USER_READ_MESSAGE
} from "../slices/chatSlices/messageSlice";

//MAIN SAGA

export function* sagas() {
  yield takeEvery(SIGN_UP, signUpSaga);
  yield takeEvery(SIGN_IN, signInSaga);
  yield takeLatest(UPDATE_TOKENS, updateTokenSaga);
  yield takeLatest(WRITE_TOKENS, writeTokensSaga);
  yield takeLatest(UPDATE_CONFIRM, confirmEmailSaga);
  yield takeLatest(LOGOUT, logoOutSaga);
  yield takeLatest(UPDATE_DETAILS, updateUserDetailsSaga);
  yield takeLatest(UPDATE_PASSWORD, updatePasswordSaga);
  yield takeLatest(SEND_LETTER, sendLetterSaga);
  yield takeLatest(CHANGE_PASSWORD, changePasswordSaga);
  yield takeLatest(GET_DETAILS, getUserDetailsSaga);
  yield takeLatest(CHANGE_EMAIL, changeEmailSaga);
  yield takeLatest(ADD_UPLOAD_FILES, fetchFileSaga);
  yield takeLatest(DELETE_FILE, deleteFileSaga);
  yield takeLatest(GET_FILES, getFilesListSaga);
  yield takeLatest(UPLOAD_FILES, handleUploadFilesSaga);
  yield takeLatest(CREATE_CHAT, createChatSaga);
  yield takeLatest(GET_CONTACTS, getContactsSaga);
  yield takeLatest(GET_CHATS, getChatsSaga);
  yield takeLatest(GET_CHATS_ITEM, getChatItemSaga);
  yield takeLatest(GET_CHAT, getChatSaga);
  yield takeLatest(SEND_MESSAGE, sendMessageSaga);
  yield takeLatest(RECEIVE_MESSAGE, messageReceiveMessageSaga);
  yield takeLatest(HUB_UPDATE_MESSAGE, hubUpdateMessageSaga);
  yield takeLatest(HUB_DELETE_MESSAGE, hubDeleteMessageSaga);
  yield takeLatest(UPDATE_MESSAGE, updateMessageSaga);
  yield takeLatest(GET_MESSAGES, getMessagesSaga);
  yield takeLatest(DELETE_MESSAGE, deleteMessageSaga);
  yield takeLatest(ADD_MEMBERS, addMembersSaga);
  yield takeLatest(GET_MEMBERS, getMembersSaga);
  yield takeEvery(USER_READ_MESSAGE, userReadMessageSaga);
  yield takeEvery(MEMBER_READ_MESSAGE, memberReadMessagesSaga);
}
