import {
  IActiveChats,
  IActiveChatState,
  IMemberDictionary,
  IMessageState
} from "../../../models/states/IChatsState";
import { createSlice } from "@reduxjs/toolkit";
import { IAction, IStoreState } from "../../../models";
import { sortMessages } from "../../../helpers";

const initialState: IActiveChats = {};

const storedChatsSlice = createSlice({
  name: 'storedChats',
  initialState,
  reducers: {
    addChatStoredChatsAction(state: IActiveChats, action: IAction<object & IActiveChatState>) {
      if (state[action.payload.id]) {
        state[action.payload.id] = {
          ...action.payload,
          scrollPos: state[action.payload.id].scrollPos
        };
      }
      else {
        state[action.payload.id] = {
          ...action.payload
        };
      }
    },
    changeLastActivityStoredChatAction(state: IActiveChats, action: IAction<{ activity: number, chatId: string, memberId: string }>) {
      state[action.payload.chatId].lastActivity = action.payload.activity;
      state[action.payload.chatId].members[action.payload.memberId].lastRead = new Date(action.payload.activity).toISOString();
    },
    storedChatDeleteMessageAction(state: IActiveChats, action: IAction<{ chatId: string, messageId: string }>) {
      if (state[action.payload.chatId]) {
        if (state[action.payload.chatId].messages) {
          state[action.payload.chatId].messages = state[action.payload.chatId].messages.filter(el => el.id !== action.payload.messageId);
        }
      }
    },
    storedChatUpdateMessageAction(state: IActiveChats, action: IAction<{ chatId: string, messageId: string, text: string }>) {
      if (state[action.payload.chatId] && state[action.payload.chatId].messages) {
        if (state[action.payload.chatId].messages.findIndex(el => el.id === action.payload.messageId) !== -1) {
          const index = state[action.payload.chatId].messages.findIndex(el => el.id === action.payload.messageId);
          state[action.payload.chatId].messages[index].text = action.payload.text;
          state[action.payload.chatId].messages[index].isModified = true;
        }
      }
    },
    addMessageStoredChatAction(state: IActiveChats, action: IAction<{ chatId: string, messages: IMessageState[] }>) {
      if (state.hasOwnProperty(action.payload.chatId)) {
        const chatId = action.payload.chatId;
        if (state[chatId].messages && action.payload.messages.length > 1) {
          state[chatId].messages = sortMessages(state[chatId].messages.concat(action.payload.messages).flat());
        }
        else {
          state[chatId].messages = state[chatId].messages.concat(action.payload.messages).flat();
        }
      }
    },
    changeStoredChatIsAllNewAction(state: IActiveChats, action: IAction<{ chatId: string, isAll: boolean }>) {
      if (state[action.payload.chatId]) {
        state[action.payload.chatId].allNew = action.payload.isAll;
      }
    },
    addMembersStoredChatAction(state: IActiveChats, action: IAction<{ chatId: string, members: IMemberDictionary }>) {
      if (state[action.payload.chatId]) {
        state[action.payload.chatId].members = action.payload.members;
      }
    },
    saveScrollPosStoredChatAction(state: IActiveChats, action: IAction<{ chatId: string, pos: number | undefined }>) {
      console.log(action.payload);
      if (state[action.payload.chatId]) {
        state[action.payload.chatId].scrollPos = action.payload.pos;
      }
    },
    clearStoredChatsStateAction(state: IActiveChats) {
      return initialState;
    }
  }
});

export const storedChatsReducer = storedChatsSlice.reducer;

export const {
  addChatStoredChatsAction,
  saveScrollPosStoredChatAction,
  clearStoredChatsStateAction,
  changeLastActivityStoredChatAction,
  addMessageStoredChatAction,
  changeStoredChatIsAllNewAction,
  storedChatDeleteMessageAction,
  storedChatUpdateMessageAction,
  addMembersStoredChatAction
} = storedChatsSlice.actions;

export const storedChatsSelector = (state: IStoreState) => state.storedChats;
export const storedChatsScrollPosSelector = (state: IStoreState, chatId: string) => state.storedChats[chatId].scrollPos;
export const storedChatsItemSelector = (state: IStoreState, id: string) => state.storedChats[id];
