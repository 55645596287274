import { IMessageStoreState } from "../../../models/states/IChatsState";
import { createAction, createSlice } from "@reduxjs/toolkit";
import {
  IAction, IHubReadMessage, IStoreState
} from "../../../models";

const initialState: IMessageStoreState = {
  msgId: '',
  isSending: false,
  isEdit: false,
  editText: '',
  isReading: false,
};

const messageSlice = createSlice({
  name: 'messageSlice',
  initialState,
  reducers: {
    messageSliceChangeIdAction(state: IMessageStoreState, action: IAction<string>) {
      state.msgId = action.payload;
    },
    messageSliceChangeIsReadingAction(state: IMessageStoreState, action: IAction<boolean>) {
      state.isReading = action.payload;
    },
    messageSliceChangeIsSendingAction(state: IMessageStoreState, action: IAction<boolean>) {
      state.isSending= action.payload;
    },
    messageSliceChangeIsEditAction(state: IMessageStoreState, action: IAction<boolean>) {
      state.isEdit = action.payload;
    },
    messageSliceChangeEditTextAction(state: IMessageStoreState, action: IAction<string>) {
      state.editText= action.payload;
    },
    messageSliceClearState(state: IMessageStoreState) {
      return initialState;
    },
  }
});

export const messageReducer = messageSlice.reducer;

export const {
  messageSliceChangeIdAction,
  messageSliceChangeIsSendingAction,
  messageSliceChangeIsEditAction,
  messageSliceChangeEditTextAction,
  messageSliceChangeIsReadingAction,
  messageSliceClearState,
} = messageSlice.actions;

export const SEND_MESSAGE = 'messageSlice/sendMessageAction';
export const sendMessageAction = createAction(SEND_MESSAGE, payload => ({ payload }));
export const UPDATE_MESSAGE = 'messageSlice/updateMessagesAction';
export const updateMessagesAction = createAction(UPDATE_MESSAGE);

export const RECEIVE_MESSAGE = 'messageSlice/receiveMessagesAction';
export const receiveMessagesAction = createAction(RECEIVE_MESSAGE, payload => ({ payload }));

export const HUB_UPDATE_MESSAGE = 'messageSlice/hubUpdateMessagesAction';
export const hubUpdateMessagesAction = createAction(HUB_UPDATE_MESSAGE, payload => ({ payload }));

export const HUB_DELETE_MESSAGE = 'messageSlice/hubDeleteMessagesAction';
export const hubDeleteMessagesAction = createAction(HUB_DELETE_MESSAGE, payload => ({ payload }));

export const DELETE_MESSAGE = 'messageSlice/deleteMessagesAction';
export const deleteMessagesAction = createAction(DELETE_MESSAGE, payload => ({ payload }));
export const USER_READ_MESSAGE = 'messageSlice/userReadMessageAction';
export const userReadMessageAction = createAction(USER_READ_MESSAGE, (payload: IHubReadMessage) => ({ payload }));

export const MEMBER_READ_MESSAGE = 'messageSlice/memberReadMessageAction';
export const memberReadMessageAction = createAction(MEMBER_READ_MESSAGE, payload => ({ payload }));

export const messageSliceIdSelector = (state: IStoreState) => state.message.msgId;
export const messageSliceIsSendingSelector = (state: IStoreState) => state.message.isSending;
export const messageSliceIsEditSelector = (state: IStoreState) => state.message.isEdit;
export const messageSliceEditTextSelector = (state: IStoreState) => state.message.editText;
export const messageSliceIsReadingSelector = (state: IStoreState) => state.message.isReading;
