export const getMessageTime = (date: string, format: 'time' | 'date' | 'value') => {
  const convertedDate = new Date(date);
  const minutes = convertedDate.getMinutes() < 10 ? '0' + convertedDate.getMinutes() : convertedDate.getMinutes();
  const hours = convertedDate.getHours() < 10 ? '0' + convertedDate.getHours() : convertedDate.getHours();
  const day = convertedDate.getDate() < 10 ? '0' + convertedDate.getDate() : convertedDate.getDate();
  const months = convertedDate.getMonth() + 1 < 10 ? '0' + (convertedDate.getMonth() + 1) : convertedDate.getMonth() + 1;
  const year = convertedDate.getFullYear() < 10 ? '0' + convertedDate.getFullYear() : convertedDate.getFullYear();

  const formats = {
    time: hours + ':' + minutes,
    date: day + '.' + months + '.' + year,
    value: convertedDate.valueOf(),
  };

  return formats[format];
};
