import {
  Box,
  Button, Checkbox,
  CircularProgress, FormControlLabel, FormGroup,
  FormHelperText,
  IconButton,
  LinearProgress,
  Paper,
  Typography
} from "@mui/material";
import {
  ChangeEvent,
  FC,
  memo,
  useCallback,

} from "react";
import { DropFileInput } from "../dropFileInput";
import CancelIcon from '@mui/icons-material/Cancel';
import { readFiles } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import UploadIcon from '@mui/icons-material/Upload';
import {
  filesAddFilesAction,
  filesDelUploadFileAction,
  uploadFilesSelector,
  uploadFilesAction,
  addUploadFileAction,
  filesUploadSelector,
  filesAddUploadFileSizeAction,
  filesDelUploadFileSizeAction
} from "../../store/slices/filesSlice";
import { IFile } from "../../models";
import './styles.scss';

type TCardProps = {
    item: any
    remove: (name: string) => void;
    index: number
}

const sizes = ['Original', 'Small', 'Medium', 'Large'];

const PreviewCard = memo<TCardProps>(function PreviewCard({ item, remove, index }) {
  const dispatch = useDispatch();

  const stopUploading = () => {
    dispatch({ type: 'STOP' + item.id });
  };

  const startUploading = () => {
    dispatch(addUploadFileAction({ item: item, id: index }));
  };

  const handleSize = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.checked ? dispatch(filesAddUploadFileSizeAction({ size: e.target.value, id: index })) :
      dispatch(filesDelUploadFileSizeAction({ size: e.target.value, id: index }));
  };

  return (
    <Paper className="preview-wrapp">
      <img className="preview-img" src={item.preview.src} alt={item.preview.name}/>
      <Box className="preview-info">
        <Typography sx={{ mb: 2 }}>{item.preview.name}</Typography>

        {
          item.error.isError ? item.error.msg.map((el: string) => <FormHelperText key={el} error={item.error.isError}>{el}</FormHelperText>) : null
        }

        { item.isUploaded && !item.isUploading ? <LinearProgress variant="determinate" color="success" value={100} /> :
          item.isUploading ? <LinearProgress /> : <LinearProgress variant="determinate" value={0} />
        }

        <Box className="preview-controls">
          { item.isUploading && !item.isUploaded ?
            <IconButton className="preview-upload-btn" disabled={item.isUploaded} onClick={stopUploading}>
              <PauseIcon className="preview-upload-icon" />
            </IconButton>
            : <IconButton className="preview-upload-btn" disabled={item.isUploaded} onClick={startUploading}>
              <PlayArrowIcon className="preview-upload-icon" />
            </IconButton>
          }

          <FormGroup className="preview-sizes">
            {sizes.map(el => (
              <FormControlLabel
                key={el}
                label={el}
                labelPlacement="bottom"
                control={
                  <Checkbox
                    value={el.toLowerCase()}
                    onChange={handleSize}
                    checked={item.data.sizes.includes(el.toLowerCase())}
                    color="success"
                  />
                } />
            ))}
          </FormGroup>
        </Box>

      </Box>
      <IconButton className="preview-remove-btn" disabled={item.isUploading} onClick={() => remove(item.id)}>
        {item.isUploaded ? <CheckCircleIcon color="success" /> : <CancelIcon className="preview-remove-icon" />}
      </IconButton>
    </Paper>
  );
});

export const AddMultipleFilesController: FC = () => {
  const storedFiles: IFile[] = useSelector(uploadFilesSelector);
  const dispatch = useDispatch();
  const isUpload = useSelector(filesUploadSelector);

  const getFiles = async (files: File[]) => {
    if (files.length > 0) {
      const filesArr = files.filter(file => storedFiles.findIndex(prev => prev.id === file.name) === -1);
      const readedFiles = await readFiles(filesArr);
      const filesForStorage: any = [];

      readedFiles.forEach(el => {
        const file = {
          id: el.name,
          data: {
            // @ts-ignore
            format: el.name.split('.').pop().toLowerCase(),
            sizes: ['original'],
            file: el.file,
          },
          isUploaded: false,
          isUploading: false,
          isCanceled: false,
          preview: {
            name: el.name,
            src: el.src,
          },
          error: {
            isError: false,
            msg: [],
          }
        };
        filesForStorage.push(file);
      });

      await dispatch(filesAddFilesAction((filesForStorage)));
    }
  };

  const clearAll = () => {
    storedFiles.forEach(el => {
      dispatch(filesDelUploadFileAction(el.id));
    });
  };

  const UploadFiles = () => {
    dispatch(uploadFilesAction());
  };

  const removeImage = useCallback((name: string) => {
    if (storedFiles.find(el => el.id === name)) {
      dispatch(filesDelUploadFileAction(name));
    }
  }, [storedFiles, dispatch]);

  return (
    <Box className="controllers-wrapper">
      <Box className="controllers-inputs-container">
        <DropFileInput isMultiple={true} onFileChange={getFiles} />
        <Box className="controllers-btns">
          <Button disabled={isUpload} onClick={clearAll} variant="outlined" color="error">
                         Clear all
            <CancelIcon sx={{ ml:1 }} />
          </Button>

          <Button disabled={isUpload} variant="contained" color="success" onClick={UploadFiles}>
                         Upload
            {isUpload ? <CircularProgress color="inherit" sx={{ ml: 1 }} size={15} /> :<UploadIcon sx={{ ml:1 }} />}
          </Button>
        </Box>

      </Box>

      <Box className="controllers__preview-list">
        { storedFiles ? storedFiles.map((el, i) => <PreviewCard key={el.preview.name} index={i} remove={removeImage} item={el} />) : null }
      </Box>
    </Box>
  );
};
