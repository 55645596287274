import { AxiosResponse } from "axios";
import {
  IChangeEmailBody, IError, ILetterResponse 
} from "../models";
import { transformError } from "../helpers";
import $api from "../http";
import { checkResponse } from "./checkResponse";

const changeEmailPath: string = `/user/email/change`;
const sendLetterPath: string = `/user/email/change`;

export const fetchChangeEmail = async (data: IChangeEmailBody): Promise<AxiosResponse<any> | IError> => {
  return $api.put(changeEmailPath, data)
    .then(res => checkResponse(res))
    .catch(e => transformError(e));
};
export const fetchEmailLatter = (data: { email: string, accessType?: string }): Promise<AxiosResponse<ILetterResponse> | IError> => {
  return $api.post(sendLetterPath, data)
    .then(res => checkResponse(res))
    .catch(e => transformError(e));
};

