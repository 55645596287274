import {
  IAction, IError, IFile
} from "../../../models";
import {
  call, put, cancelled
} from "@redux-saga/core/effects";
import {
  filesAddUploadFileErrorAction,
  filesChangeIsUploadedAction, filesChangeIsUploadingAction,
} from "../../slices/filesSlice";
import { fetchFilesPost } from "../../../services/FileService";
import { getFilesListSaga } from "./getFilesListSaga";

export function* fetchFileSaga(action: IAction<{ item: IFile, id: number }>): Generator {
  const controller = yield new AbortController();

  try {
    if (!action.payload.item.isUploaded) {
      if (action.payload.item.data.sizes.length < 1) {
        throw new Error('Can\'t be upload without size');
      }
      else {
        yield put(filesAddUploadFileErrorAction({ error: { isError: false, msg: [] }, id: action.payload.id }));

        yield put(filesChangeIsUploadingAction({ status: true, id: action.payload.id }));
        const resp = yield call(() => fetchFilesPost(action.payload.item.data, (controller as AbortController)));

        if ((resp as object).hasOwnProperty('errorCode') && (resp as IError).errorCode !== 10) {
          throw resp;
        }
        else {
          yield put(filesChangeIsUploadedAction({ status: true, id: action.payload.id }));
          yield put(filesChangeIsUploadingAction({ status: false, id: action.payload.id }));
          yield call(() => getFilesListSaga());
        }
      }
    }
    else {
      throw new Error('This file is already uploaded!');
    }
  }
  catch (e) {
    yield put(filesAddUploadFileErrorAction({ error: { isError: true, msg: [(e as Error).message] }, id: action.payload.id }));
  }
  finally {
    yield put(filesChangeIsUploadingAction({ status: false, id: action.payload.id }));
    if (yield cancelled()) {
      (controller as AbortController).abort();
    }
  }
}
