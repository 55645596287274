import { call, put } from "@redux-saga/core/effects";
import { addChatsAction, changeIsLoadChatsAction } from "../../slices/chatSlices/chatsListSlice";
import { fetchGetChats } from "../../../services/ChatService";
import { IChatItem, IError } from "../../../models";

export function* getChatsSaga(): Generator {
  try {
    yield put(changeIsLoadChatsAction(true));

    const res = yield call(() => fetchGetChats());
    if ((res as object).hasOwnProperty('errorCode') && (res as IError).errorCode !== 10) {
      throw res;
    }
    else {
      yield put(addChatsAction((res as IChatItem[])));
    }
  }
  catch (e) {
    console.log(e);
  }
  finally {
    yield put(changeIsLoadChatsAction(false));
  }
}
