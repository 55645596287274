import React, {
  FC, useEffect, useRef, useState
} from "react";
import {
  Avatar, Box, Chip, List, ListItem
} from "@mui/material";
import { IActiveChatState } from "../../../models/states/IChatsState";
import './style.scss';
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../../store/slices/userSlice";
import { getMessageTime } from "../../../helpers";
import { chatItemSelector, getMessagesAction } from "../../../store/slices/chatSlices/chatsListSlice";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { uiChangeMessageAction } from "../../../store/slices/uiSlice";
import { MessageCard } from "../message-card";
import { MessageViewer } from "../messageViewer";
import {
  deleteMessagesAction,
  messageSliceChangeEditTextAction,
  messageSliceChangeIdAction, messageSliceChangeIsEditAction, messageSliceIsSendingSelector
} from "../../../store/slices/chatSlices/messageSlice";
import { saveScrollPosStoredChatAction, } from "../../../store/slices/chatSlices/storedChatsSlice";
import { changeActiveChatScrollPosAction } from "../../../store/slices/chatSlices/activeChatSlice";
import { IStoreState } from "../../../models";

type TListProps = {
    chat: IActiveChatState,
}

export const MessagesList: FC<TListProps> = ({ chat }) => {
  const user = useSelector(userSelector);
  const listRef = useRef(null);
  const dispatch = useDispatch();
  const [msgData, setMsgData] = useState({ id: '', text: '' });
  const scrollTimeoutRef = useRef();
  const isSending = useSelector(messageSliceIsSendingSelector);
  const chatItem = useSelector((state: IStoreState) => chatItemSelector(state, chat.id));

  const deleteMessage = () => {
    dispatch(deleteMessagesAction(msgData.id));
  };

  const editMessage = () => {
    dispatch(messageSliceChangeEditTextAction(msgData.text));
    dispatch(messageSliceChangeIdAction(msgData.id));
    dispatch(messageSliceChangeIsEditAction(true));
  };

  const menuItems = [
    {
      label: 'Delete',
      icon: <DeleteIcon />,
      callback: () => dispatch(uiChangeMessageAction({
        isShow: true,
        title: 'Delete',
        desc: 'Do you want to delete this message?',
        role: 'del',
        callback: deleteMessage
      }))
    },
    {
      label: 'Edit',
      icon: <EditIcon />,
      callback: editMessage
    }
  ];

  const handleScroll = (e: any) => {
    const timeout = scrollTimeoutRef.current;
    clearInterval(timeout);
    if (chat.messages.length > 1) {
      const target = (e.target as HTMLElement);
      if (Math.ceil((target.scrollTop / (target.scrollHeight - target.clientHeight)) * 100) <= 25) {
        if ((chat && !chat.allOld) && !chat.isRequesting) {
          dispatch(getMessagesAction({ date: chat.messages[0].sentDate, id: chat.id, backwards: true }));
        }
      }

      else if (Math.ceil((target.scrollTop / (target.scrollHeight - target.clientHeight)) * 100) >= 75) {
        if ((chat && !chat.allNew) && !chat.isRequesting && chatItem && chatItem.unread !== 0) {
          dispatch(getMessagesAction({ date: chat.messages[chat.messages.length - 1].sentDate, id: chat.id, backwards: false }));
        }
      }
      // @ts-ignore
      scrollTimeoutRef.current = setTimeout(() => {
        dispatch(saveScrollPosStoredChatAction({ chatId: chat.id, pos: target.scrollTop }));
      }, 300);
    }
  };

  useEffect(() => {
    const list = listRef.current;
    if (chat.id && list) {
      if (chat.scrollPos && chat.scrollPos >= 0 && !chat.isRequesting) {
        (list as HTMLElement).scrollTo({ top: chat.scrollPos, behavior: 'auto' });
      }
      else if (chat.scrollPos && chat.scrollPos < 0 && !chat.isRequesting) {
        (list as HTMLElement).scrollTo(
          {
            top: (list as HTMLElement).scrollHeight + (list as HTMLElement).clientHeight,
            behavior: 'auto'
          });

        dispatch(changeActiveChatScrollPosAction((list as HTMLElement).scrollTop));
      }
      else {
        (list as HTMLElement).scrollTo(
          {
            top: ((list as HTMLElement).scrollHeight + (list as HTMLElement).clientHeight) / 2,
            behavior: 'auto'
          });
      }
    }

    return () => {
      if (list) {
        (list as HTMLElement).removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat.id, isSending, dispatch, chat.messages]);

  return (
    <List
      ref={listRef}
      className="messages-list styled-scroll"
      onScroll={handleScroll}
    >
      {chat.allOld || chat.messages.length < 19 ? <Chip className="time__chip" label={getMessageTime(chat.createdDate, 'date')} />: null}
      {
        chat.messages.map((el, i) => {
          const pos = el.senderId === user.id ? 'right' : 'left';

          return (
            <Box key={el.id}>
              <MessageViewer
                chatId={chat.id}
                key={el.id}
                message={el}
                member={{ id: user.id, ...chat.members[user.id] }}
                children={

                  <ListItem
                    className={`messages-item ${pos}`}
                  >
                    {
                      chat.chatType.toLowerCase() === 'group' && el.senderId !== user.id ?
                        <Avatar className="avatar">{chat.members[el.senderId].name[0].toUpperCase()}</Avatar>
                        : null
                    }
                    <MessageCard
                      isSeen={chat.lastActivity >= getMessageTime(el.sentDate, 'value')
                                                    && el.senderId === user.id
                      }
                      message={el}
                      isName={user.id !== el.senderId}
                      position={pos}
                      senderName={chat.members[el.senderId].name}
                      menuItems={menuItems}
                      sendMsg={setMsgData}
                    />
                  </ListItem>
                } />
              {
                chat.messages[i + 1] && getMessageTime(chat.messages[i + 1].sentDate, 'date') !== getMessageTime(el.sentDate, 'date') ?
                  <Chip className="time__chip" label={getMessageTime(chat.messages[i + 1].sentDate, 'date')} />
                  : null
              }
            </Box>);
        })
      }

    </List>
  );
};
