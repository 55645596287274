import { IAction } from "../../../models";
import { put, select } from "@redux-saga/core/effects";
import { getChatsItemAction } from "../../slices/chatSlices/chatsListSlice";
import { activeChatIdSelector, deleteMessageActiveChatAction } from "../../slices/chatSlices/activeChatSlice";
import { storedChatDeleteMessageAction, storedChatsSelector } from "../../slices/chatSlices/storedChatsSlice";
import { IActiveChatStoreState } from "../../../models/states/IChatsState";

export function* hubDeleteMessageSaga(action: IAction<{ messageId: string, chatId: string }>): Generator {
  try {
    const activeChatId = yield select(activeChatIdSelector);
    if (activeChatId === action.payload.chatId) {
      yield put(deleteMessageActiveChatAction(action.payload));
    }
    else {
      const storedChats = yield select(storedChatsSelector);
      if ((storedChats as IActiveChatStoreState).hasOwnProperty(action.payload.chatId)) {
        yield put(storedChatDeleteMessageAction(action.payload));
      }
    }

    yield put(getChatsItemAction(action.payload.chatId));
  }
  catch (e) {
    console.log(e);
  }
}
