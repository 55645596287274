import { IError, IUserClaims } from "../../../models";
import { jwtDecode } from "jwt-decode";
import { put } from "@redux-saga/core/effects";
import {
  addUserEmailAction, addUserIdAction, changeUserTypeAction
} from "../../slices/userSlice";
import {
  addExpireAction, addLogTimeAction, addRefreshValidAction, updateEmailConfirmAction
} from "../../slices/tokensSlice";

export function* decodeJwtSaga(jwt: string) {
  try {
    let res: IUserClaims | IError;
    const userClaims: IUserClaims | unknown = yield jwtDecode(jwt, { header: true });

    if ((userClaims as IUserClaims).access_type !== 'Admin') {
      //USER DATA FROM CLAIMS
      yield put(addUserEmailAction((userClaims as IUserClaims).unique_name));
      yield put(addUserIdAction((userClaims as IUserClaims).nameid));
      // TOKENS DATA FROM CLAIMS
      yield put(updateEmailConfirmAction((userClaims as IUserClaims).email_confirmed));
      yield put(addRefreshValidAction((userClaims as IUserClaims).refresh_valid));
      yield put(changeUserTypeAction((userClaims as IUserClaims).access_type));
      yield put(addExpireAction((userClaims as IUserClaims).exp));
      yield put(addLogTimeAction((userClaims as IUserClaims).nbf));

      res = yield (userClaims as IUserClaims);
    }
    else {
      res = yield {
        status:  'Rights',
        errorCode: -1,
        messages: { Email: ["Sorry but you haven't enough rights for access"] }
      };
    }

    return res;
  }
  catch (e) {
    console.log(e);
  }
}
