import { useEffect } from "react";
import { logoutAction, tokensRefValidSelector } from "../store/slices/tokensSlice";
import { useDispatch, useSelector } from "react-redux";

export const useCheckExpireByView = () => {
  const refValidDate = useSelector(tokensRefValidSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleExpire = () => {
      if (document.visibilityState === 'visible') {
        const nowDate = new Date().valueOf();
        if (refValidDate && +refValidDate * 1000 < nowDate) {
          dispatch(logoutAction());
        }
      }
    };

    document.addEventListener('visibilitychange', handleExpire);

    return () => {
      document.removeEventListener('visibilitychange', handleExpire);
    };
  }, [refValidDate, dispatch]);
};
