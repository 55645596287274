import { IAction, IError } from "../../../models";
import { call, put } from "@redux-saga/core/effects";
import { errorsUiUpdateAction } from "../../slices/errorSlice";
import { fetchFilesDelete } from "../../../services/FileService";
import { filesAddImageAction } from "../../slices/filesSlice";
import { uiChangeIsShowMessageAction, uiChangeMessageAction } from "../../slices/uiSlice";
import { getFilesListSaga } from "./getFilesListSaga";

export function* deleteFileSaga(action: IAction<string>): Generator {
  try {
    yield put(errorsUiUpdateAction(null));
    yield put(uiChangeIsShowMessageAction(false));
    const resp = yield call(() => fetchFilesDelete(action.payload));

    if ((resp as object).hasOwnProperty('errorCode') && (resp as IError).errorCode !== 10) {
      throw resp;
    }
    else {
      yield put(uiChangeMessageAction({
        isShow: true,
        title: 'Deleted!',
        desc: 'Image was deleted',
        role: 'inf'
      }));
      yield put(filesAddImageAction(null));
      yield call(() => getFilesListSaga());
    }
  }
  catch (e) {
    yield put(errorsUiUpdateAction(e as IError));
  }
}
