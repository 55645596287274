import {
  call, put, select
} from "@redux-saga/core/effects";
import { hubReadMessages } from "../../../hub";
import {
  IAction, IChatItem, IHubResponse, IStoreState
} from "../../../models";
import { changeUnreadInChatItem, chatItemSelector } from "../../slices/chatSlices/chatsListSlice";
import { activeChatSelector, changeLastActivityAction } from "../../slices/chatSlices/activeChatSlice";
import { IActiveChatState } from "../../../models/states/IChatsState";
import { messageSliceChangeIsReadingAction } from "../../slices/chatSlices/messageSlice";

export function* userReadMessageSaga(action: IAction<{ chatId: string, readAmount: number, readPoint: string, readBackwards: boolean }>): Generator {
  try {
    yield put(messageSliceChangeIsReadingAction(true));
    const chat = yield select(activeChatSelector);
    const chatItem = yield select((state: IStoreState) => chatItemSelector(state, (chat as IActiveChatState).id));
    if (chatItem && (chatItem as IChatItem[]).length > 0) {
      const resp = yield call(() => hubReadMessages(action.payload));
      if ((resp as IHubResponse).success && (resp as IHubResponse).data) {
        yield put(changeLastActivityAction({
          memberId: (resp as IHubResponse).data.memberId,
          activity: new Date((resp as IHubResponse).data.lastRead).valueOf(),
          isChat: false,
        }));

        yield put(changeUnreadInChatItem({
          chatId: action.payload.chatId,
          unread: (resp as IHubResponse).data.unread
        }));
      }
    }
  }
  catch (e) {
    console.log(e);
  }
  finally {
    yield put(messageSliceChangeIsReadingAction(false));
  }
}
