import { useEffect, useRef } from "react";
import { useFormik } from "formik";

export const useFormikRef = (initialValues: any, validationSchema: any, onSubmit: any) => {
  const formikRef = useRef<any>(null);

  const formik = useFormik<any>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  formikRef.current = formik;

  useEffect(() => {
    formikRef.current = formik;
  }, [formik]);

  return formikRef;
};

