import { fetchGetContacts } from "../../../services/ChatService";
import { call, put } from "@redux-saga/core/effects";
import { IError, IUserState } from "../../../models";
import { addChatContactsAction } from "../../slices/chatSlices/chatsListSlice";

export function* getContactsSaga(): Generator {
  try {
    const response = yield call(() => fetchGetContacts());
    if ((response as object).hasOwnProperty('errorCode') && (response as IError).errorCode !== 10) {
      throw response;
    }
    else {
      const contacts = yield (response as any[]).filter(el => el.details !== null && el.userAccess !== 'Admin');
      yield put(addChatContactsAction((contacts as IUserState[])));
    }
  }
  catch (e) {
    console.log(e);
  }
}
