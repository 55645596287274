import {
  ChangeEvent, FC, useRef, useState
} from "react";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import './style.scss';
import {
  Box, FormHelperText, Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { filesUploadSelector } from "../../store/slices/filesSlice";

type TProps = {
    onFileChange: (files: any) => void,
    isMultiple?: boolean,
}

const ValidFormats = ['gif', 'png', 'jpg', 'jpeg'];

export const DropFileInput: FC<TProps> = (props) => {
  const wrapperRef = useRef(null);
  const [error, setIsError] = useState({ isError: false, msg: '' });
  const isUpload = useSelector(filesUploadSelector);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (((e.target.files as FileList).length < 2 || !props.isMultiple) && e.target.files) {
      // @ts-ignore
      const format = e.target.files[0].name.split('.').pop().toLowerCase();
      if (ValidFormats.includes(format)) {
        setIsError({ ...error, isError: false });
        props.onFileChange((e.target.files as FileList)[0]);
      }
      else {
        setIsError({ isError: true, msg: 'Not valid file format valid formats are: PNG, GIF, JPG' });
      }
    }
    if (props.isMultiple && e.target.files) {
      const result = [];
      const files = e.target.files;

      for (let i = 0; i <= files.length - 1; i++) {
        // @ts-ignore
        const format = files[i].name.split('.').pop().toLowerCase();
        if (ValidFormats.includes(format)) {
          result.push(files[i]);
        }
        else if (!ValidFormats.includes(format)) {
          setIsError({
            isError: true,
            msg: 'One of your files is not valid! \n valid formats are :  PNG, GIF, JPG'
          });
        }
      }
      props.onFileChange(result);
    }
  };

  // @ts-ignore
  const onDragEnter = () => (wrapperRef.current).classList.add('dragover');

  // @ts-ignore
  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

  // @ts-ignore
  const onDrop = () => wrapperRef.current.classList.remove('dragover');

  // @ts-ignore
  return (
    <Box sx={{ width: '100%', height: '100%', position:"relative" }}>
      <Box
        ref={wrapperRef}
        className="drop-file-input"
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <Box className="drop-file-input__label">
          <AddAPhotoIcon />
          <Typography sx={{ pt: 1 }}> Click  or Drop</Typography>
        </Box>
        <input disabled={isUpload} multiple={props.isMultiple} type="file" accept="image/*" value="" onChange={handleChange} />
      </Box>
      {error.isError ?
        <FormHelperText sx={{ pl: 1 }} error={error.isError}>{error.msg}</FormHelperText> :
        <FormHelperText sx={{ pl: 1 }} > PNG, GIF, JPG</FormHelperText>
      }
    </Box>
  );
};
