import {
  Box, Button, Divider, Typography
} from "@mui/material";
import { PasswordInput } from "../../password-input";
import { validation } from "../../../schemas/validation.schemas";
import './style.scss';
import { useDispatch, useSelector } from "react-redux";
import { errorsUpdatePasswordSelector } from "../../../store/slices/errorSlice";
import { useEffect } from "react";
import {
  addUpdateCurrPasswordAction,
  addUpdateNewPasswordAction,
  settingsUpdatePasswordSelector,
  updatePasswordAction
} from "../../../store/slices/settingsSlice";
import { useFormikRef } from "../../../hooks";
import { setError } from "../../../helpers/setError";

export const FormUpdatePassword = () => {
  const dispatch = useDispatch();
  const error = useSelector(errorsUpdatePasswordSelector);
  const updateState = useSelector(settingsUpdatePasswordSelector);

  const onSubmit = (values: { password: string, repPassword: string, newPassword: string }) => {
    dispatch(addUpdateCurrPasswordAction(values.password));
    dispatch(addUpdateNewPasswordAction(values.newPassword));
    dispatch(updatePasswordAction());
  };

  const formikRef = useFormikRef(
    {
      password: updateState.currentPassword,
      repPassword: '',
      newPassword: updateState.newPassword
    },
    validation.updatePassword(),
    onSubmit,
  );
  const formik = formikRef.current;

  useEffect(() => {
    if (error) {
      setError(formikRef.current, error, 'updatePassword');
    }

    return;
  }, [error, formikRef]);

  return (
    <Box sx={{ maxWidth: '300px', mt:10, marginLeft: 'auto', marginRight: 'auto' }}>
      <Typography fontSize="xx-large" component="h1" variant="h5">
                Update password
      </Typography>
      <Box className="update-password_form" component="form" sx={{ mt: 1 }} onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit(e);
      }}>

        <PasswordInput
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors?.password)}
          helperText={formik.errors?.password as string}

        />

        <Box className="new-password_container">

          <PasswordInput
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors?.newPassword)}
            helperText={formik.errors?.newPassword as string}
            name="newPassword"
            label={'New password'}
          />

          <PasswordInput
            value={formik.values.repPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors?.repPassword)}
            helperText={formik.errors?.repPassword as string}
            label="Confirm new password"
            name="repPassword"
          />

        </Box>

        <Divider sx={{ border: '1px solid #000', opacity: '0.25', mb: '1rem' }} variant="middle" />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Button color="success" variant="contained" type="submit" >Update</Button>
        </Box>
      </Box>
    </Box>
  );
};
