import { AxiosResponse } from "axios";
import {
  IChangePasswordBody, IError, ILetterResponse, IUpdatePasswordBody
} from "../models";
import { transformError } from "../helpers";
import $api from "../http";
import { checkResponse } from "./checkResponse";

const updatePasswordPath: string = `/user/password`;
const resetPassPath: string = `/user/password/reset`;

export const fetchPasswordUpdate = (data: IUpdatePasswordBody): Promise<AxiosResponse<string> | IError> => {
  return $api.put(updatePasswordPath, data)
    .then(res => checkResponse(res))
    .catch(e => transformError(e));
};

export const fetchPasswordChange = (data: IChangePasswordBody): Promise<AxiosResponse<any> | IError> => {
  return $api.put(resetPassPath, data)
    .then(res => checkResponse(res))
    .catch(e => transformError(e));
};

export const fetchPasswordLetter = (data: { email: string }): Promise<AxiosResponse<ILetterResponse> | IError> => {
  return $api.post(resetPassPath, data)
    .then(res => checkResponse(res))
    .catch(e => transformError(e));
};

