import React, { FC, Suspense } from "react";

import Header from "../header";
import { Typography } from "@mui/material";
import Copyright from "../copyright";
import { useSelector } from "react-redux";
import { uiIsLoadingSelector } from "../../store/slices/uiSlice";
import { ErrorBoundary } from "react-error-boundary";
import { CustomContainer } from "../customContainer";

type TProps = {
    header?: boolean,
    footer?: boolean,
    children: any,
}

export const Layout: FC<TProps> = ({ header, footer, children }) => {
  const loading = useSelector(uiIsLoadingSelector);

  return !loading ? (
    <>
      {
        header ? <Header /> : null
      }
      <CustomContainer component="main">
        <Suspense fallback={<>Routing...</>}>
          <ErrorBoundary fallback={<Typography>Ooops! something went wrong try to reload page</Typography>}>
            {children}
          </ErrorBoundary>
        </Suspense>
      </CustomContainer>

      {
        footer ? <Copyright /> : null
      }
    </>
  ) : <>Loading...</>;
};
