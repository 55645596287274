import { IAction, IChatItem } from "../../../models";
import { put, select } from "@redux-saga/core/effects";
import { IActiveChats } from "../../../models/states/IChatsState";
import { changeLastMessageInChatItem, chatsSelector } from "../../slices/chatSlices/chatsListSlice";
import { storedChatsSelector, storedChatUpdateMessageAction } from "../../slices/chatSlices/storedChatsSlice";
import { updateMessageActiveChatAction } from "../../slices/chatSlices/activeChatSlice";

export function* hubUpdateMessageSaga (action: IAction<{ chatId: string, messageId: string, text: string }>): Generator {
  const storedChats = yield select(storedChatsSelector);
  const chats = yield select(chatsSelector);
  if ((chats as IChatItem[]).findIndex(el => el.id === action.payload.chatId) !== -1) {
    const index = (chats as IChatItem[]).findIndex(el => el.id === action.payload.chatId);
    if ((storedChats as IActiveChats).hasOwnProperty(action.payload.chatId)) {
      yield put(updateMessageActiveChatAction(action.payload));
      yield put(storedChatUpdateMessageAction(action.payload));
    }

    if ((chats as IChatItem[])[index].latestMessage.id === action.payload.messageId) {
      yield put(changeLastMessageInChatItem({
        id: action.payload.chatId,
        message: action.payload.text,
        unread: 0,
        role: 'upd'
      }));
    }
  }
}
