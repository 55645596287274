import {
  call, put, select
} from "@redux-saga/core/effects";
import {
  IAction, IChat, IError, IMemberState, IUserState,
} from "../../../models";
import { fetchGetChat } from "../../../services/ChatService";
import { IActiveChatState } from "../../../models/states/IChatsState";
import { addChatStoredChatsAction } from "../../slices/chatSlices/storedChatsSlice";
import {
  changeActiveChatAction, changeActiveChatIsLoadAction, changeActiveChatScrollPosAction,
} from "../../slices/chatSlices/activeChatSlice";
import { createDictionaryFromArray, sortMessages } from "../../../helpers";
import { userSelector } from "../../slices/userSlice";

export function* getChatSaga(action: IAction<{ id: string, name: string, unread: number }>): Generator {
  try {
    yield put(changeActiveChatIsLoadAction(true));

    const res = yield call(() => fetchGetChat(action.payload.id));
    if ((res as object).hasOwnProperty('errorCode') && (res as IError).errorCode !== 10) {
      throw res;
    }

    else {
      let chatData;
      // @ts-ignore
      const dictionary = yield call(() => createDictionaryFromArray((res.members)));
      const user = yield select(userSelector);
      let lastActivity: number = 0;

      // @ts-ignore
      yield (res as IChat).members.forEach((el: IMemberState) => {
        if (el.id !== (user as IUserState).id) {
          lastActivity = Math.max(new Date(lastActivity).valueOf(), new Date(el.lastRead).valueOf());
        }
      });

      chatData = yield Object.assign(
        { name: action.payload.name,
          allOld: false,
          allNew: false,
          lastActivity },
        (res as any),
        { members: dictionary });

      if ((chatData as IActiveChatState).messages && (chatData as IActiveChatState).messages.length > 0) {
        yield (chatData as IActiveChatState).messages = sortMessages((res as IActiveChatState).messages);
      }

      yield put(changeActiveChatAction((chatData as IActiveChatState)));
      yield put(addChatStoredChatsAction(chatData as IActiveChatState));
      if (action.payload.unread === 0) {
        yield put(changeActiveChatScrollPosAction(-1));
      }
    }
  }
  catch (e) {
    console.log(e);
  }
  finally {
    yield put(changeActiveChatIsLoadAction(false));
  }
}
