import {
  Box, IconButton, Typography
} from "@mui/material";
import './style.scss';
import { CustomMenu } from "../../menu";
import React, { FC, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
type TProps = {
    itemsForMenu: {
        label: string,
        callback: () => void
        icon?: any
    }[]
};

export const ChatListHeader: FC<TProps> = ({ itemsForMenu }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box className="chat-list__header">
        <Typography>
                    Chats
        </Typography>
        <Box>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <CustomMenu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            el={anchorEl}
            open={open}
            onClose={handleClose}
            items={itemsForMenu}
          />
        </Box>
      </Box>
    </>

  );
};
