import {
  FC, useEffect, useState, memo
} from "react";
import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { uiTimerSelector, uiUpdateTimerAction } from "../../store/slices/uiSlice";

type TProps = {
    makeResend?: () => void,
    text: string,
    variant?: "text" | "outlined" | "contained",
    type?: "button" | 'submit',
}

export const ButtonWithTimer: FC<TProps> = memo(({ makeResend, text, type, variant }) => {
  const time = useSelector(uiTimerSelector);
  const [timerValue, setTimerValue] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const dispatch = useDispatch();

  const handleClick = () => {
    if (makeResend) {
      setIsDisabled(true);
      makeResend();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimerValue(timerValue - 1);
    }, 1000);

    if (+timerValue <= 0) {
      clearInterval(timer);
      setIsDisabled(false);
    }

    return;
  }, [timerValue]);

  useEffect(() => {
    if (time > 0) {
      setTimerValue(time);
    }
    dispatch(uiUpdateTimerAction(0));

    return;
  }, [time, dispatch]);

  return (
    <Box>
      <Button onClick={handleClick} type={type ? type : 'button'} variant={variant ? variant : 'text'} disabled={timerValue > 0 || isDisabled}> {timerValue <= 0 ? text : `${timerValue}`} </Button>
    </Box>

  );
});

export default ButtonWithTimer;
