import { createAction, createSlice } from "@reduxjs/toolkit";
import {
  IAction, ISignUpState, IStoreState
} from "../../models";

const initialState: ISignUpState = {
  email: '',
  password: '',
};

export const signUpSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    signUpAddEmailAction: (state: ISignUpState, action: IAction<string>) => {
      state.email = action.payload;
    },
    signUpAddPasswordAction: (state: ISignUpState, action: IAction<string>) => {
      state.password = action.payload;
    },
    signUpClearStateAction: (state: ISignUpState) => {
      state.email = '';
      state.password = '';
    }
  }
});

export const signUpReducer = signUpSlice.reducer;

export const { signUpAddEmailAction, signUpAddPasswordAction, signUpClearStateAction } = signUpSlice.actions;
export const SIGN_UP = 'signUpSlice/signUpAction';
export const signUpAction = createAction(SIGN_UP, payload => ({ payload }));

export const signUpEmailSelector = (state: IStoreState) => state.signUp.email;
export const signUpPasswordSelector = (state: IStoreState) => state.signUp.password;
export const signUpStoreSelector = (state: IStoreState) => state.signUp;
