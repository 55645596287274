import { FC, useState } from "react";
import {
  Box, Drawer, IconButton, Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import MenuIcon from '@mui/icons-material/Menu';
import './style.scss';
import { MenuList } from "../menu-list";
import { userSelector } from "../../store/slices/userSlice";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { CustomContainer } from "../customContainer";
import Logo from '../../assets/images/logo_reflum.png';
import { useClearErrorsNavigate } from "../../hooks";
import { CHAT } from "../../routes/paths";

export const Header: FC = () => {
  const [showMenu, setShowMenu] = useState(false);
  const user = useSelector(userSelector);
  const navigation = useClearErrorsNavigate();

  const toggleList = (newOpen: boolean) => () => {
    setShowMenu(newOpen);
  };

  return (
    <Box className="header" component={"header"}>
      <CustomContainer>

        <Box className="header__inner">
          <Box className="header__inputs">
            <IconButton className="header__btn" onClick={toggleList(true)}>
              <MenuIcon className="header__menu-icon"></MenuIcon>
            </IconButton>
            <Drawer open={showMenu} onClose={toggleList(false)}>
              {<MenuList showMenu={setShowMenu}></MenuList>}
            </Drawer>

            <IconButton className="header__btn" onClick={() => navigation(CHAT)}>
              <NotificationsIcon className="header__chat-icon"></NotificationsIcon>
            </IconButton>

            <Box className="header__user-details">
              <AccountCircleIcon fontSize={"large"} />
              <Typography>
                {user.details.name ? user.details.name : user.email}
              </Typography>
            </Box>
          </Box>

          <img style={{ maxWidth: '100px' }} src={Logo} alt="logo"/>
        </Box>
      </CustomContainer>
    </Box>
  );
};
export default Header;
