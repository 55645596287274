import {
  call, put, select
} from "@redux-saga/core/effects";
import { fetchGetMessages } from "../../../services/ChatService";
import { IAction, IError } from "../../../models";
import { IMessageState } from "../../../models/states/IChatsState";

import {
  activeChatIsAllNewSelector,
  activeChatIsAllOldSelector,
  addMessagesActiveChatAction,
  changeActiveChatIsAllNewAction,
  changeActiveChatIsAllOldAction,
  changeActiveChatIsReqAction,
} from "../../slices/chatSlices/activeChatSlice";

export function* getMessagesSaga(action: IAction<{ date: string, id: string, backwards: boolean }>): Generator {
  try {
    yield put(changeActiveChatIsReqAction(true));

    const isAllOld = yield select(activeChatIsAllOldSelector);
    const isAllNew = yield select(activeChatIsAllNewSelector);

    if (action.payload.id && ((action.payload.backwards && !isAllOld) || (!action.payload.backwards && !isAllNew))) {
      const data = yield {
        chatId: action.payload.id,
        seekBackwards: action.payload.backwards,
        startFrom: action?.payload.date
      };

      const resp = yield call(() => fetchGetMessages(data));

      if ((resp as object).hasOwnProperty('errorCode') && (resp as IError).errorCode !== 10) {
        throw resp;
      }

      else {
        if ((resp as IMessageState[]).length > 1) {
          const fixedList = (resp as IMessageState[]).slice(1, (resp as IMessageState[]).length);
          yield put(addMessagesActiveChatAction((fixedList as IMessageState[])));
        }
        else {
          if (action.payload.backwards && !isAllOld) {
            yield put(changeActiveChatIsAllOldAction(true));
          }
          else if (!action.payload.backwards && !isAllNew) {
            yield put(changeActiveChatIsAllNewAction(true));
          }
        }
      }
    }
  }
  catch (e) {
    console.log(e);
  }
  finally {
    yield put(changeActiveChatIsReqAction(false));
  }
}
