import { errorsUiUpdateAction } from "../../slices/errorSlice";
import { call, put } from "@redux-saga/core/effects";
import { fetchFilesGet } from "../../../services/FileService";
import { filesAddDownFilesAction, filesChangeLoad } from "../../slices/filesSlice";
import { IError, IFileResponse } from "../../../models";

export function* getFilesListSaga(): Generator {
  try {
    yield put(errorsUiUpdateAction(null));
    yield put(filesChangeLoad(true));
    const resp = yield call(() => fetchFilesGet());

    if ((resp as object).hasOwnProperty('errorCode') && (resp as IError).errorCode !== 10) {
      throw resp;
    }
    else {
      yield put(filesAddDownFilesAction(resp as IFileResponse[]));
    }
  }
  catch (e) {
    yield put(errorsUiUpdateAction(e as IError));
  }
  finally {
    yield put(filesChangeLoad(false));
  }
}
