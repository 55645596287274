import {
  ListItemIcon, ListItemText, Menu, MenuItem, MenuListProps
} from "@mui/material";
import React, { FC } from "react";

type TMenuProps = {
    items: { label: string, callback: () => void, icon?: any }[];
    el: null | HTMLElement
    open: boolean,
    onClose: () => void,
    id: string,
    MenuListProps: MenuListProps
}

export const CustomMenu: FC<TMenuProps> = (props) => {
  const { items, el, open, onClose, id, MenuListProps } = props;
  const handleAdd = (callback?: () => void) => {
    if (callback) {
      callback();
    }
    onClose();
  };

  return (<div>
    <Menu
      id={id}
      MenuListProps={MenuListProps}
      anchorEl={el}
      open={open}
      onClose={onClose}
    >

      {items.map((el: any) =>
        <MenuItem key={el.label} onClick={() => handleAdd(el.callback)}>
          {el.icon && <ListItemIcon>{el.icon}</ListItemIcon>}
          <ListItemText>{el.label}</ListItemText>
        </MenuItem>
      )}
    </Menu>
  </div>);
};
