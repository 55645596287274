import { put, call } from "@redux-saga/core/effects";
import { clearTokensAction } from "../slices/tokensSlice";
import { logOutUserAction } from "../slices/userSlice";
import { Router } from "@remix-run/router";
import { getContext } from "redux-saga/effects";
import { SIGN_IN } from "../../routes/paths";
import { uiChangeIsShowMessageAction, uiUpdateTimerAction } from "../slices/uiSlice";
import { signInClearStateAction } from "../slices/signInSlice";
import { signUpClearStateAction } from "../slices/signUpSlice";
import { fetchSignOut } from "../../services/AuthService";
import { AxiosResponse } from "axios";
import { clearSettings } from "../slices/settingsSlice";
import { hubCloseConnection } from "../../hub";
import { clearChatsListState } from "../slices/chatSlices/chatsListSlice";
import { clearStoredChatsStateAction } from "../slices/chatSlices/storedChatsSlice";
import { clearActiveChatState } from "../slices/chatSlices/activeChatSlice";

export function* logoOutSaga(): Generator {
  try {
    yield call(() => hubCloseConnection());
    const resp = yield call(() => fetchSignOut());

    if ((resp as AxiosResponse).status === 204) {
      yield put(logOutUserAction());
      yield put(clearSettings());
      yield put(clearChatsListState());
      yield put(clearTokensAction());
      yield put(signInClearStateAction());
      yield put(signUpClearStateAction());
      yield put(clearActiveChatState());
      yield put(clearStoredChatsStateAction());
      yield put(uiUpdateTimerAction(0));
      yield put(uiChangeIsShowMessageAction(false));
      yield call([localStorage, localStorage.clear]);
      const router: Router | unknown = yield (getContext('router'));
      yield (router as Router).navigate(SIGN_IN, { replace: true });
    }
  }
  catch (e) {
    console.log(e);
  }
}
