import {
  CHAT,
  EMAIL,
  EMAIL_CHANGE, FILE, FILES,
  FLOOD,
  FORGOT_PASSWORD, FORGOT_PASSWORD_CHANGE,
  HOME, MERCHANT,
  SIGN_IN,
  SIGN_UP,
  UPDATE_PASSWORD,
  USER_DETAILS
} from "./paths";
import { Layout } from "../components/layout";
import { RouteGuardBydKey, RouteGuardByType } from "./guardianRoute";
import { lazy } from "react";

const ChangeEmailPage = lazy(() => import('../pages/change-email-page'));
const NotFoundPage = lazy(() => import('../pages/not_found-page'));
const SendEmailChangePage = lazy(() => import('../pages/send_email_change-page'));
const UserDetailsPage = lazy(() => import('../pages/user_details-page'));
const HomePage = lazy(() => import('../pages/home-page'));
const UpdatePasswordPage = lazy(() => import('../pages/update_password-page'));
const ChangePassPage = lazy(() => import('../pages/change_pass-page'));
const FilesPage = lazy(() => import('../pages/files-page/'));
const FilePage = lazy(() => import('../pages/file-page/'));

const ShopPage = lazy(() => import('../pages/shop-page'));
const SendResetPage = lazy(() => import('../pages/send_reset_pass-page'));
const SignInPage = lazy(() => import('../pages/sign_in-page'));
const SignUpPage = lazy(() => import('../pages/sign_up-page'));
const ChatPage = lazy(() => import('../pages/chat-page'));

export const auth_routes = [
  {
    path: SIGN_UP,
    element: <Layout children={<SignUpPage />}/>,
  },
  {
    path: SIGN_IN,
    element: <Layout children={<SignInPage />}/>,
  },
  {
    path: FORGOT_PASSWORD,
    element: <Layout children={<SendResetPage />}/>,
  },

  {
    element: <RouteGuardBydKey goTo={SIGN_IN} />,
    children: [
      {
        path: FORGOT_PASSWORD_CHANGE,
        element: <Layout children={<ChangePassPage />}/>,
      },
    ]
  }

];

export const merchant_routes = [
  {
    path: MERCHANT,
    element: <Layout header={true} children={<ShopPage />}/>,
  }
];

export const main_routes = [
  {
    path: HOME,
    element: <Layout header={true} children={<HomePage />}/>,
  },
  {
    path: CHAT,
    element: <Layout header={true} children={<ChatPage />}/>,
  },
  {
    path: USER_DETAILS,
    element: <Layout header={true} children={<UserDetailsPage />}/>,
  },
  {
    path: UPDATE_PASSWORD,
    element: <Layout header={true} children={<UpdatePasswordPage />}/>,
  },
  {
    path: EMAIL,
    element: <Layout header={true} children={<SendEmailChangePage />}/>,
  },
  {
    path: FILES,
    element: <Layout header={true} children={<FilesPage />}/>,
  },
  {
    path: FILE,
    element: <Layout header={true} children={<FilePage />}/>,
  },
  {
    path: FLOOD,
    element: <Layout header={false} children={<NotFoundPage />}/>,
  },

  {
    element: <RouteGuardByType type={'Merchant'} goTo={HOME} />,
    children: merchant_routes
  },

  {
    element: <RouteGuardBydKey goTo={HOME} />,
    children: [
      {
        path: EMAIL_CHANGE,
        element: <Layout header={false} children={<ChangeEmailPage />}/>,
      },
    ]
  }
];
