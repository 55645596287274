import { Box } from "@mui/material";
import {
  FC, ReactNode, useEffect
} from "react";
import { IMemberState, IMessageState } from "../../../models/states/IChatsState";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { messageSliceIsReadingSelector, userReadMessageAction } from "../../../store/slices/chatSlices/messageSlice";
import { getMessageTime } from "../../../helpers";

type TProps ={
    children: ReactNode,
    message: IMessageState,
    member: IMemberState,
    chatId: string,
}

export const MessageViewer: FC<TProps> = ({ children, message, member, chatId }) => {
  const { inView, ref } = useInView({
    triggerOnce: true, // Trigger only once when the element comes into view
    threshold: 0.5, // 50% of the element needs to be visible
  });
  const dispatch = useDispatch();
  const isRead = useSelector(messageSliceIsReadingSelector);

  useEffect(() => {
    if ((inView && message.senderId !== member.id) && !isRead) {
      const messageDate = getMessageTime(message.sentDate, 'value');
      const memberLastRead = getMessageTime(member.lastRead, 'value');
      if (messageDate > memberLastRead) {
        console.log('bvc');
        dispatch(userReadMessageAction({
          chatId,
          readAmount: 1,
          readPoint: message.sentDate,
          readBackwards: false
        }));
      }
    }
  }, [inView, message, chatId, dispatch, member, isRead]);

  return (
    <Box ref={ref}>
      {children}
    </Box>
  );
};
