import { IAction, IHubResponse } from "../../../models";
import {
  call, put, select
} from "@redux-saga/core/effects";
import { hubAddMembers } from "../../../hub";
import { getMembersAction } from "../../slices/chatSlices/chatsListSlice";
import { uiChangeMessageAction } from "../../slices/uiSlice";
import { activeChatIdSelector } from "../../slices/chatSlices/activeChatSlice";

export function* addMembersSaga(action: IAction<string[]>): Generator {
  try {
    const chatId = yield select(activeChatIdSelector);
    const res = yield call(() => hubAddMembers({ chatId: (chatId as string), members: action.payload }));
    console.log(res);
    if ((res as IHubResponse).success) {
      yield put(getMembersAction(chatId));
      yield put(uiChangeMessageAction({
        isShow: true,
        title: 'Added',
        desc: 'Members  have been added',
        role: 'inf'
      }));
    }
    else {
      throw res;
    }
  }
  catch (e) {
    console.log(e);
  }
}
