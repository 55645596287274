import {
  Avatar, Box, Button, Checkbox, IconButton, List, ListItem, Typography
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addTmplChatAction, chatContactsSelector, createChatAction
} from "../../../store/slices/chatSlices/chatsListSlice";
import { FC, useState } from "react";
import MessageIcon from '@mui/icons-material/Message';
import './style.scss';
import { IUserState } from "../../../models";
import { uiChangeMessageAction } from "../../../store/slices/uiSlice";
import { userSelector } from "../../../store/slices/userSlice";

type TProps = {
    handleClose: () => void;
    returnList?: (ids: string[]) => void;
    members: string[];
    items: IUserState[],
    role: 'members' | 'contacts'
}

export const ContactsList: FC<TProps> = ({ handleClose, returnList, members, role, items }) => {
  const contacts = useSelector(chatContactsSelector);
  const user = useSelector(userSelector);
  const [ids, setId] = useState<string[]>(members);
  let createChat: ((arg0: IUserState) => void) | null = null;
  let handleMessage: ((arg0: IUserState) => void) | null = null;
  const dispatch = useDispatch();

  const handleId = (id: string) => {
    if (ids.includes(id)) {
      setId(ids.filter(el => el !== id));
    }
    else {
      setId(ids.concat(id));
    }
  };

  const sendIds = () => {
    if (returnList) {
      returnList(ids);

      if (handleClose) {
        handleClose();
      }
    }
  };

  if (role === 'contacts') {
    createChat = (el: IUserState) => {
      dispatch(addTmplChatAction({
        chatType: 'private',
        name: `${el.details.name} ${el.details.surname}`,
        members: [user.id, el.id]
      }));
      dispatch(createChatAction());
      handleClose();
    };

    handleMessage = (el: IUserState) => {
      dispatch(uiChangeMessageAction({
        isShow: true,
        title: '',
        desc: `Would you like to start chatting with \n ${el.details.name + ' ' + el.details.surname}`,
        role: 'acc',
        callback:  () => createChat ? createChat(el) : undefined
      }));
    };
  }

  return contacts ? (
    <Box className="contacts-wrap">
      <List className="contacts-list styled-scroll">
        {
          items.map(el => (
            <ListItem className="contacts-item" key={el.id} onClick={() => handleId(el.id)}>
              <Avatar
                alt={el.details.name}
              >
                {el.details.name[0].toUpperCase() + el.details.surname[0].toUpperCase()}
              </Avatar>

              <Box className="contacts-item__info">
                <Typography className="contacts-item__info-name">
                  {`${el.details.name} ${el.details.surname}`}
                </Typography>
                <Typography className="contacts-item__info-email">
                  {el.email}
                </Typography>
              </Box>

              {
                role === 'members' &&
                                <Checkbox
                                  className="contacts-item__checkbox"
                                  color="success"
                                  checked={ids.includes(el.id)}
                                  value={el.id}
                                />
              }
              {
                role === 'contacts' && <IconButton onClick={() => handleMessage ? handleMessage(el) : null}>
                  <MessageIcon />
                </IconButton>
              }

            </ListItem>
          ))
        }
      </List>

      <Box className={'contacts_btns'}>
        <Button onClick={handleClose}>
                    Cancel
        </Button>
        { role === 'members' ?
          <Button onClick={sendIds} disabled={ids.length < 1}>
                        Add members
          </Button> : null
        }

      </Box>
    </Box>

  ) : <Typography>Wait..</Typography>;
};
