import { createSlice } from "@reduxjs/toolkit";
import {
  IUiState, IStoreState, IAction, IUiMessage
} from "../../models";

const initialState: IUiState = {
  isLoading: false,
  timer: 0,
  message: {
    isShow: false,
    title: '',
    desc: '',
    role: 'inf'
  }
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    uiChangeLoadingAction(state: IUiState, action: IAction<boolean>) {
      state.isLoading = action.payload;
    },

    uiUpdateTimerAction(state: IUiState, action: IAction<number>) {
      state.timer = action.payload;
    },
    uiChangeMessageAction(state: IUiState, action: IAction<IUiMessage>) {
      state.message = action.payload;
    },
    uiChangeIsShowMessageAction(state: IUiState, action: IAction<boolean>) {
      state.message.isShow = action.payload;
    }
  }

});

export const uiReducer = uiSlice.reducer;

// ACTIONS
export const { uiChangeLoadingAction,
  uiUpdateTimerAction,
  uiChangeMessageAction,
  uiChangeIsShowMessageAction } = uiSlice.actions;
// SELECTOR

export const uiIsLoadingSelector = (state: IStoreState) => state.ui.isLoading;
export const uiMessageSelector = (state: IStoreState) => state.ui.message;
export const uiTimerSelector = (state: IStoreState) => state.ui.timer;

