import { IMessageState } from "../models/states/IChatsState";

export const sortMessages = (messages: IMessageState[]) => {
  let result: IMessageState[] = [];
  if (messages && messages.length > 1) {
    result = [...messages].sort((a, b) => {
      if (new Date(a.sentDate).valueOf() > new Date(b.sentDate).valueOf()) {
        return 1;
      } else if (new Date(a.sentDate).valueOf() < new Date(b.sentDate).valueOf()) {
        return -1;
      } else {
        return 0;
      }
    });
  }
  else {
    result = [messages].flat();
  }

  return result;
};
